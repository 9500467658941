import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CodeSetChooser,
  ComponentAndGroupChooser,
  DatatypeChooser,
  FieldChooser,
  MessageChooser,
  SearchBox,
} from './Choosers';
import { OrchestraResource, OrchestraSpec } from './OrchestraSpec';
import { usePathHelper } from './Path';

function compareResourcesByName<T extends OrchestraResource>(
  resource1: T,
  resource2: T
) {
  return resource1.name.localeCompare(resource2.name);
}

type NavigationProps = {
  orchestraSpec: OrchestraSpec | null;
};

export function Navigation(props: NavigationProps) {
  const spec = props.orchestraSpec;
  const navigate = useNavigate();

  const pathHelper = usePathHelper();

  const componentsAndGroups = [
    ...(spec?.getComponents() || []),
    ...(spec?.getGroups() || []),
  ].sort(compareResourcesByName);
  const messages = [...(spec?.getMessages() || [])].sort(
    compareResourcesByName
  );
  const fields = [...(spec?.getFields() || [])].sort(compareResourcesByName);
  const datatypes = [...(spec?.getDatatypes() || [])].sort(
    compareResourcesByName
  );
  const codeSets = [...(spec?.getCodeSets() || [])].sort(
    compareResourcesByName
  );

  const navigateToResource = useCallback(
    (resource: OrchestraResource | null) => {
      if (!resource) {
        return;
      }

      navigate(pathHelper.toResource(resource));
    },
    [navigate, pathHelper]
  );

  const commonSearchAndChooserProps = {
    // we want these to clear after choosing an option, so null out the value
    value: null,
    disabled: spec === null,
  };

  const commonChooserProps = {
    ...commonSearchAndChooserProps,
    blurOnSelect: true,
    setValue: navigateToResource,
  };

  return (
    <>
      <SearchBox
        {...commonSearchAndChooserProps}
        search={(searchQuery) =>
          navigate({
            pathname: 'search',
            search: `?q=${searchQuery}`,
          })
        }
      />
      <MessageChooser {...commonChooserProps} items={messages} />
      <ComponentAndGroupChooser
        {...commonChooserProps}
        items={componentsAndGroups}
        setValue={navigateToResource}
      />
      <FieldChooser
        {...commonChooserProps}
        items={fields}
        setValue={navigateToResource}
      />
      <CodeSetChooser
        {...commonChooserProps}
        items={codeSets}
        setValue={navigateToResource}
      />
      <DatatypeChooser
        {...commonChooserProps}
        items={datatypes}
        setValue={navigateToResource}
      />
    </>
  );
}
