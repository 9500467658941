import React, { createContext, ReactNode, useContext } from 'react';
import { CodeSetView, DatatypeView, FieldView } from './OrchestraSpecViews';
import { OrchestraSpecWithInfoContext } from './OrchestraSpecManager';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Drawer as MuiDrawer } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import {
  resourceFromSlug,
  ResourceSlugSpecifier,
  slugFromResource,
} from './Path';
import { InternalLink } from './InternalLink';

export const detailPaneQueryParam = 'detailPane';

const IsInBottomDrawerContext = createContext(false);
export const useIsInBottomDrawer = () => useContext(IsInBottomDrawerContext);

export const BottomDrawerLink = ({
  linkContent,
  resource,
}: {
  linkContent?: ReactNode;
  resource: ResourceSlugSpecifier;
}) => {
  const spec = useContext(OrchestraSpecWithInfoContext)?.spec;
  const [searchParams] = useSearchParams();

  if (!spec) {
    return null;
  }

  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set(detailPaneQueryParam, slugFromResource(resource));

  return (
    <InternalLink to={`?${newSearchParams.toString()}`}>
      {linkContent || resource.name}
    </InternalLink>
  );
};

const useBottomDrawerResource = () => {
  const [searchParams] = useSearchParams();
  const detailSlug = searchParams.get(detailPaneQueryParam);
  return (detailSlug && resourceFromSlug(detailSlug)) || null;
};

export const useIsBottomDrawerOpen = () => {
  const resource = useBottomDrawerResource();
  return resource !== null;
};

export const BottomDrawer = () => {
  const spec = useContext(OrchestraSpecWithInfoContext)?.spec;
  const setSearchParams = useSearchParams()[1];
  const resource = useBottomDrawerResource();

  if (!spec) {
    return null;
  }

  return (
    <MuiDrawer
      variant='persistent'
      anchor='bottom'
      open={resource !== null}
      PaperProps={{ sx: { height: '40vh' } }}
    >
      <IsInBottomDrawerContext.Provider value={true}>
        <Box
          data-testid='bottom-drawer'
          sx={{ display: 'flex', flexDirection: 'column', px: 4, py: 2 }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 20, right: 20 }}
            onClick={() =>
              setSearchParams((searchParams) => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.delete(detailPaneQueryParam);
                return newSearchParams;
              })
            }
          >
            <Close />
          </IconButton>
          {resource && <BottomDrawerContent resource={resource} />}
        </Box>
      </IsInBottomDrawerContext.Provider>
    </MuiDrawer>
  );
};

export const BottomDrawerContent = ({
  resource,
}: {
  resource: ResourceSlugSpecifier;
}) => {
  const spec = useContext(OrchestraSpecWithInfoContext)?.spec;
  if (!spec) {
    return null;
  }

  const { type, name } = resource;
  const field = type === 'field' && spec.getFieldByName(name);
  if (field) {
    return <FieldView field={field} usedIn={spec.getFieldUsedIn(field)} />;
  }

  const codeSet = type === 'codeSet' && spec.getCodeSetByName(name);
  if (codeSet) {
    return (
      <CodeSetView codeSet={codeSet} usedIn={spec.getCodeSetUsedIn(codeSet)} />
    );
  }

  const datatype = type === 'datatype' && spec.getDatatypeByName(name);
  if (datatype) {
    return <DatatypeView datatype={datatype} />;
  }

  return null;
};
