import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import TocIcon from '@mui/icons-material/Toc';

type IndexedResource =
  | 'messages'
  | 'components'
  | 'groups'
  | 'fields'
  | 'codeSets'
  | 'datatypes';
const INDEXED_RESOURCES: IndexedResource[] = [
  'messages',
  'components',
  'groups',
  'fields',
  'codeSets',
  'datatypes',
];

export function IndexNavigation({ disabled }: { disabled?: boolean }) {
  const items = INDEXED_RESOURCES.map((variant) => (
    <IndexNavigationItem key={variant} variant={variant} disabled={disabled} />
  ));

  return (
    <>
      <Typography sx={{ ml: 2, mt: 2 }} variant='h6' component='div'>
        Indexes
      </Typography>
      <List component='div' dense>
        {items}
      </List>
    </>
  );
}
function IndexNavigationItem({
  variant,
  disabled,
}: {
  variant: IndexedResource;
  disabled?: boolean;
}) {
  const title = {
    messages: 'Messages',
    components: 'Components',
    groups: 'Groups',
    fields: 'Fields',
    codeSets: 'Code Sets',
    datatypes: 'Datatypes',
  }[variant];

  return (
    <ListItemButton
      component={RRLink}
      to={variant}
      sx={{ pl: 3 }}
      disabled={disabled}
    >
      <ListItemIcon>
        <TocIcon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
}
