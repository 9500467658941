import { getOrchimateVersion } from './metadata';
import { getErrorText } from './utils';

const ORCHIMATE_EMAIL = 'orchimate@atomicwire.io';
const FOOTER_SEPARATOR =
  '-------------------------------------------------------';

type Footers = { [key: string]: string };
const getDataFooter = (extraFooters: Footers = {}) => {
  const footers = {
    'Orchimate version': getOrchimateVersion(),
    Timestamp: new Date().toISOString(),
    ...extraFooters,
  };

  return [
    FOOTER_SEPARATOR,
    ...Object.entries(footers).map(([k, v]) => `${k}: ${v}`),
  ].join('\n');
};

export type EmailComponentProps = {
  templateName: string;
  email: string;
  subject: string;
  body: string;
};

const emailProps = (
  templateName: string,
  subject: string,
  body: string,
  extraFooters: Footers = {}
): EmailComponentProps => ({
  templateName: templateName,
  email: ORCHIMATE_EMAIL,
  subject,
  body: `${body.trim()}\n\n${getDataFooter(extraFooters).trim()}`,
});

export const reportDefectEmailProps = (error?: unknown) =>
  emailProps(
    'report-defect',
    'Report Defect: [Brief Description of Defect]',
    `
  I am writing to report a defect that I encountered while using Orchimate. Below are the details of the issue:
  
    - Description: [Brief description of the defect encountered]
    - Steps to Reproduce:
      1. [Step 1]
      2. [Step 2]
      3. [Step 3]
    - Expected Behavior: [Description of what was expected to happen]
    - Actual Behavior: [Description of what actually happened]
    - Screenshots/Attachments: [If applicable, attach any relevant screenshots or files]
  
  This defect has impacted [explain how the defect has impacted your experience].
  
  Please let me know if you require any further information or assistance from my end.
  
  Regards,
  
  [Your Name]
  [Your Position/Role]
  [Your Contact Information]
  `,
    error ? { 'Exception Details': getErrorText(error) } : {}
  );

export const requestNewFeatureEmailProps = () =>
  emailProps(
    'request-new-feature',
    'Request New Feature: [Brief Description of Feature]',
    `
I am reaching out to request the implementation of a new feature in Orchimate. Below are the details of the proposed feature:

  - Description: [Brief description of the new feature]
  - Benefits: [Explain how this feature would enhance the user experience or add value to the app]
  - Use Case: [Provide a scenario or example where this feature would be useful]
  - Mockups/Examples: [If available, include any mockups or examples to visualize the proposed feature]

I believe that the addition of this feature would greatly improve [explain how it would improve the app or benefit the users].

Please let me know if you require any further information or clarification regarding this request.

Regards,

[Your Name]
[Your Position/Role]
[Your Contact Information]
`
  );

export const addPreLoadedSpecEmailProps = () =>
  emailProps(
    'add-pre-loaded-spec',
    'Add Pre-Loaded Specification: [Specification Name]',
    `
  I am writing to request the addition of a pre-loaded specification to Orchimate. Below are the details of the specification:

  - Specification Name: [Name of the specification]
  - Description: [Brief description of the specification and its purpose]
  - Source: [Link to the source documentation]
  - Repository: [Link to the Orchestra XML file or repository]
  - Parameters/Details: [List any specific parameters or details that should be included in the specification]

I believe that pre-loading this specification in Orchimate would greatly benefit [explain how it would benefit the users].

Please let me know if you require any further information or assistance regarding this request.

Regards,

[Your Name]
[Your Position/Role]
[Your Contact Information]
`
  );

export const generalEnquiryEmailProps = () =>
  emailProps(
    'general-enquiry',
    'General Enquiry Regarding Orchimate',
    `
I am writing to inquire about a general matter regarding Orchimate. As a user of your application, I have a question that does not fall under any specific category or topic area.

Could you please assist me with the following enquiry:

[Insert your question or enquiry here.]

Please let me know if you require any further information or assistance regarding this request.

Regards,

[Your Name]
[Your Position/Role]
[Your Contact Information]
`
  );

export const orchestraAdoptionEmailProps = () =>
  emailProps(
    'orchestra-adoption',
    'Request for Assistance with Orchestra Adoption',
    `
I am reaching out to request assistance with adopting Orchestra for our project/team. Below are the details of our request:

  - Current Status: [Briefly describe your current understanding or usage of the Orchestra specification, if any.]
  - Reason for Adoption: [Explain why you believe adopting Orchestra would benefit your project/team.]
  - Support Needed: [Specify the type of assistance or support you require to successfully adopt Orchestra. This could include training, documentation, implementation guidance, etc.]

We recognize the potential advantages of integrating Orchestra specifications into our workflow and are eager to ensure a smooth transition. Your expertise and guidance in this matter would be invaluable to us.

We kindly request your assistance in helping us navigate the process of adopting Orchestra effectively.

Please let us know your availability for a discussion or meeting to further discuss this request and determine the best course of action.

Regards,

[Your Name]
[Your Position/Role]
[Your Contact Information]
`
  );

export const specAuthoringEmailProps = () =>
  emailProps(
    'spec-authoring',
    'Request for Assistance with Orchestra Specification Authoring',
    `
I am reaching out to request your assistance with authoring an Orchestra specification for our project/team. Below are the details of our request:

  - Scope of Specification: [Briefly describe the scope and purpose of the Orchestra specification you need assistance with.]
  - Current Progress: [Outline any progress made so far in authoring the specification, if any.]
  - Specific Areas Needing Assistance: [Specify any particular sections or aspects of the specification where you require help, such as structuring, content creation, defining message types, build process, etc.]

We recognize the importance of having a well-structured and comprehensive Orchestra specification for our project/team, and we believe your expertise in this area would greatly benefit us.

We kindly request your assistance in guiding us through the process of authoring the Orchestra specification effectively.

Please let us know your availability for a discussion or meeting to further discuss this request and determine the best course of action.

Regards,

[Your Name]
[Your Position/Role]
[Your Contact Information]
`
  );

export const enterpriseEditionEmailProps = () =>
  emailProps(
    'enterprise-edition',
    'Expression of Interest in Enterprise Edition of Orchimate',
    `
I am writing to express our interest in exploring the Enterprise Edition of Orchimate. Our team has been utilizing the standard version of Orchimate and we believe that upgrading to the Enterprise Edition could provide us with additional features and benefits tailored to our organizational needs.

We are particularly interested in enterprise features such as [mention specific features or benefits that are appealing to your organization].

Could we please schedule a demo or discussion to further explore how the Enterprise Edition of Orchimate can meet the specific requirements of our organization?

Regards,

[Your Name]
[Your Position/Role]
[Your Contact Information]
`
  );
