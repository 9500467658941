import React from 'react';
import {
  Box,
  Container,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import { Link as RRLink } from 'react-router-dom';
import { OrchestraSpec } from './OrchestraSpec';
import {
  OrchestraSpecInfo,
  useOrchestraSpecManager,
} from './OrchestraSpecManager';
import { MarkdownLinksOnly } from './Markdown';
import { ObfuscatedEmailLink } from './ObfuscatedEmail';
import {
  addPreLoadedSpecEmailProps,
  generalEnquiryEmailProps,
  reportDefectEmailProps,
  requestNewFeatureEmailProps,
} from './getInTouchEmailTemplates';
import { privacyPolicyLink, termsOfUseLink } from './Legal';
import { Navigation } from './Navigation';
import { MessageLayouts } from './MessageLayouts';
import { IndexNavigation } from './IndexNavigation';
import { useIsSmallScreenLayout } from './smallScreenLayout';

function SpecEntry({ specInfo }: { specInfo: OrchestraSpecInfo }) {
  return (
    <ListItemButton component={RRLink} to={`/${specInfo.slug}`}>
      <ListItemIcon>
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText
        primary={specInfo.name}
        primaryTypographyProps={{ fontSize: 'h5.fontSize' }}
      />
    </ListItemButton>
  );
}

function NoSpec() {
  const specManager = useOrchestraSpecManager();

  const specListEntries = (specManager?.orchestraSpecs || []).map(
    (specInfo) => <SpecEntry key={specInfo.slug} specInfo={specInfo} />
  );

  return (
    <Container maxWidth='lg'>
      <Typography variant='h4' data-testid='welcome-header'>
        Welcome to Orchimate
      </Typography>
      <Typography paragraph sx={{ mt: 2 }}>
        Orchimate is a search tool to visualise and explore your {orchestraLink}{' '}
        specifications.
      </Typography>
      <Typography paragraph>
        Use of Orchimate is governed by the {termsOfUseLink} and the{' '}
        {privacyPolicyLink}.
      </Typography>
      <Typography paragraph>
        To get started, you can load your own specification using the "Add Local
        Spec" button or select from the list of pre-loaded specifications.
      </Typography>
      <Typography paragraph>
        If you would like to add a pre-loaded specification to Orchimate, please{' '}
        {addPreloadedSpecLink} or via the help menu. You can also{' '}
        {reportDefectLink}, {requestFeatureLink}, or send a {generalInquiryLink}
        .
      </Typography>
      {specListEntries.length > 0 && (
        <List
          data-testid='spec-list'
          sx={{ mt: 3, bgcolor: 'background.paper' }}
          dense
        >
          {specListEntries}
        </List>
      )}
      {!specManager?.ready && <Skeleton variant='rectangular' height={50} />}
    </Container>
  );
}

const orchestraLink = (
  <Link
    href={'https://www.fixtrading.org/standards/fix-orchestra/'}
    target='_blank'
    rel='noopener noreferrer'
  >
    Orchestra
  </Link>
);

const addPreloadedSpecLink = (
  <ObfuscatedEmailLink {...addPreLoadedSpecEmailProps()}>
    request it here
  </ObfuscatedEmailLink>
);

const reportDefectLink = (
  <ObfuscatedEmailLink {...reportDefectEmailProps()}>
    report a defect
  </ObfuscatedEmailLink>
);

const requestFeatureLink = (
  <ObfuscatedEmailLink {...requestNewFeatureEmailProps()}>
    request a new feature
  </ObfuscatedEmailLink>
);

const generalInquiryLink = (
  <ObfuscatedEmailLink {...generalEnquiryEmailProps()}>
    general inquiry
  </ObfuscatedEmailLink>
);

// Capitalize the first letter of the given string and add a space prior to each subsequent capital letter
function titleCase(text: string): string {
  return (
    text.charAt(0).toUpperCase() + text.slice(1).replace(/([A-Z])/g, ' $1')
  );
}

function MetadataEntry({ name, value }: { name: string; value: string }) {
  return (
    <ListItem>
      <ListItemText
        primary={titleCase(name)}
        primaryTypographyProps={{ color: 'text.secondary' }}
        secondary={<MarkdownLinksOnly>{value}</MarkdownLinksOnly>}
        secondaryTypographyProps={{
          color: 'text.primary',
          fontSize: '110%',
        }}
      />
    </ListItem>
  );
}

function WithSpec({ spec }: { spec?: OrchestraSpec }) {
  const isSmallScreenLayout = useIsSmallScreenLayout();

  let listContent = (
    <ListItem>
      <ListItemText secondary={<Skeleton />} />
    </ListItem>
  );

  if (spec) {
    const metadataEntries = Array.from(spec.metadata).map(([name, value]) => (
      <MetadataEntry key={name} name={name} value={value} />
    ));

    if (metadataEntries.length > 0) {
      listContent = <>{metadataEntries}</>;
    } else {
      listContent = (
        <ListItem>
          <ListItemText secondary={<em>No metadata available</em>} />
        </ListItem>
      );
    }
  }

  return (
    <Container maxWidth='lg'>
      <Typography data-testid='spec-name-header' variant='h3'>
        {spec ? spec.name : <Skeleton />}
      </Typography>
      <Typography variant='h5' color='text.secondary'>
        {spec ? spec.version : <Skeleton />}
      </Typography>
      <List sx={{ mt: 3, bgcolor: 'background.paper' }} dense>
        {listContent}
      </List>
      {spec && isSmallScreenLayout && (
        <Box
          sx={{ mt: 4, pt: 2, bgcolor: 'background.paper' }}
          data-testid='spec-home-nav'
        >
          <Typography sx={{ ml: 2 }} variant='h6' component='div'>
            Resources
          </Typography>
          <List component='nav' sx={{ pt: 0 }}>
            <Navigation orchestraSpec={spec} />
          </List>
          <Divider sx={{ mt: 2 }} />
          <IndexNavigation />
          <Divider sx={{ mt: 2 }} />
          <MessageLayouts orchestraSpec={spec} />
        </Box>
      )}
    </Container>
  );
}

export function Home() {
  return <NoSpec />;
}

export function SpecHome({ orchestraSpec }: { orchestraSpec?: OrchestraSpec }) {
  return <WithSpec spec={orchestraSpec} />;
}
