import { HasPedigree } from './OrchestraSpec';
import { intersperse, isDefined } from './utils';

export function formatPedigree(hasPedigree: HasPedigree) {
  const added = formatPedigreePart(
    'Added',
    hasPedigree.added,
    hasPedigree.addedEP
  );

  const updated = formatPedigreePart(
    'Updated',
    hasPedigree.updated,
    hasPedigree.updatedEP
  );

  const deprecated = formatPedigreePart(
    'Deprecated',
    hasPedigree.deprecated,
    hasPedigree.deprecatedEP
  );

  return (
    <>{intersperse([added, updated, deprecated].filter(isDefined), ', ')}</>
  );
}

function formatPedigreePart(
  action: 'Added' | 'Updated' | 'Deprecated',
  version?: string,
  ep?: string
): string | undefined {
  if (version === 'FIX.5.0SP2' && ep !== undefined) {
    return `${action} EP${ep}`;
  } else if (version !== undefined) {
    return `${action} ${version}`;
  }

  return undefined;
}
