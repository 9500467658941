import { XMLParser } from 'fast-xml-parser';

import {
  HasDocumentation,
  HasId,
  HasPedigree,
  HasRefs,
  HasScenario,
  OrchestraCategoryTree,
  OrchestraCodeSet,
  OrchestraCodeSetCode,
  OrchestraCodeSetCodeIdIndex,
  OrchestraCodeSetIdIndex,
  OrchestraComponent,
  OrchestraComponentFieldGroupRef,
  OrchestraComponentIdIndex,
  OrchestraDatatype,
  OrchestraDatatypeNameIndex,
  OrchestraField,
  OrchestraFieldIdIndex,
  OrchestraGroup,
  OrchestraGroupIdIndex,
  OrchestraMessage,
  OrchestraMessageIdIndex,
  OrchestraReferrable,
  OrchestraResourceBase,
  OrchestraResourceIdIndex,
  OrchestraResourceNameIndex,
  OrchestraResourceType,
  OrchestraSpec,
  OrchestraSpecMetadata,
} from './OrchestraSpec';

const DEFAULT_SCENARIO = 'base';

/* eslint-disable  @typescript-eslint/no-explicit-any */
type XmlAny = any;
/* eslint-enable  @typescript-eslint/no-explicit-any */

class XmlElement {
  private readonly tagName: string;

  constructor(private delegate: XmlAny) {
    const tagName = XmlElement.extractTagName(delegate);
    if (tagName === undefined) {
      throw Error('Invalid Orchestra XML');
    }

    this.tagName = tagName;
  }

  private static extractTagName(delegate: XmlAny): string | undefined {
    return Object.keys(delegate).find((key) => key !== '@:');
  }

  public getTagName(): string {
    return this.tagName;
  }

  public findOne(name: string): XmlElement | undefined {
    const element = this.delegate[this.tagName].find(
      (element: XmlAny) => name in element
    );
    if (element === undefined) {
      return undefined;
    }

    return new XmlElement(element);
  }

  public findAll(name?: string): XmlElement[] {
    let elements: XmlAny[] = this.delegate[this.tagName];
    if (name !== undefined) {
      elements = elements.filter((element: XmlAny) => name in element);
    }

    return elements.map((element: XmlAny) => new XmlElement(element));
  }

  public attribute(key: string): string | undefined {
    return this.attributes()[key];
  }

  public attributes(): { [key: string]: string } {
    return this.delegate[':@'] || {};
  }

  public text(): string | undefined {
    return this.delegate[this.tagName].find(
      (element: XmlAny) => '#text' in element
    )?.['#text'];
  }
}

const XML_PARSER = new XMLParser({
  ignoreAttributes: false,
  attributeNamePrefix: '',
  preserveOrder: true,
});

type IdNameAbbr = {
  id: number;
  name: string;
  abbrName?: string;
};

export class OrchestraSpecXmlParser {
  public static parseXml(xmlData: string): OrchestraSpec {
    const xmlDocument = XML_PARSER.parse(xmlData);
    const root = new XmlElement({ _root: xmlDocument });
    const repository = srsly(root.findOne('fixr:repository'));
    return buildOrchestraSpecFromXmlDocument(repository);
  }
}

function buildOrchestraSpecFromXmlDocument(
  repository: XmlElement
): OrchestraSpec {
  const { name, version } = extractNameAndVersion(repository);
  const metadata = extractMetadata(repository);

  const codeSetIndex: OrchestraCodeSetIdIndex = extractResources(
    repository,
    'codeSet',
    extractCodeSet
  );

  // Code set codes are nested within a code set (note: codes already filtered to default scenario in extractCodeSet)
  const codeSetCodeIndex: OrchestraCodeSetCodeIdIndex = new Map(
    Array.from(codeSetIndex.values())
      .flatMap((codeSet) => codeSet.codes)
      .map((codeSetCode) => [codeSetCode.id, codeSetCode])
  );

  const componentIndex: OrchestraComponentIdIndex = extractResources(
    repository,
    'component',
    extractComponent
  );

  const datatypeIndex: OrchestraDatatypeNameIndex = extractResourcesByName(
    repository,
    'datatype',
    extractDatatype
  );

  const fieldIndex: OrchestraFieldIdIndex = extractResources(
    repository,
    'field',
    extractField
  );

  const groupIndex: OrchestraGroupIdIndex = extractResources(
    repository,
    'group',
    extractGroup
  );

  const messageIndex = extractResources(repository, 'message', (messageSpec) =>
    extractMessage(messageSpec, componentIndex, fieldIndex, groupIndex)
  );

  populateAllComponentAndGroupRefs(
    repository,
    componentIndex,
    fieldIndex,
    groupIndex
  );

  const categoryTree = buildCategoryTree(repository, messageIndex);

  return new OrchestraSpec(
    name,
    version,
    metadata,

    codeSetCodeIndex,
    codeSetIndex,
    componentIndex,
    datatypeIndex,
    fieldIndex,
    groupIndex,
    messageIndex,

    categoryTree
  );
}

function extractNameAndVersion(repository: XmlElement): {
  name: string;
  version: string;
} {
  const name = srsly(repository.attribute('name'));
  const version = srsly(repository.attribute('version'));
  return { name, version };
}

function stripNamespaceAlias(tagName: string): string {
  const separatorIdx = tagName.indexOf(':');
  return tagName.substring(separatorIdx + 1);
}

function extractMetadata(repository: XmlElement): OrchestraSpecMetadata {
  const metadata = new Map<string, string>();

  const repositoryMetadata = repository.findOne('fixr:metadata');
  if (repositoryMetadata === undefined) {
    return metadata;
  }

  repositoryMetadata.findAll().map((element) => {
    const dcFieldName = stripNamespaceAlias(element.getTagName());
    const dcFieldValue = element.text();
    if (dcFieldValue) {
      metadata.set(dcFieldName, dcFieldValue);
    }
  });

  return metadata;
}

/**
 * Note: this function currently filters resources to their base scenario (specifically, {@link DEFAULT_SCENARIO}) when constructing an index.
 */
function iterateResourceCollection(
  repository: XmlElement,
  resourceType: OrchestraResourceType,
  resourceTypePlural?: string
): XmlElement[] {
  if (!resourceTypePlural) {
    resourceTypePlural = `${resourceType}s`;
  }

  const collectionTag = `fixr:${resourceTypePlural}`;
  const resourceTag = `fixr:${resourceType}`;

  const collection = repository.findOne(collectionTag);
  if (collection === undefined) {
    return [];
  }

  return collection.findAll(resourceTag).filter(filterToDefaultScenario);
}

function filterToDefaultScenario(element: XmlElement): boolean {
  const { scenario } = extractScenario(element);
  return scenario === DEFAULT_SCENARIO;
}

function extractResources<T extends HasId & HasScenario>(
  repository: XmlElement,
  resourceType: OrchestraResourceType,
  resourceConstructor: (element: XmlElement) => T
): OrchestraResourceIdIndex<T> {
  const map: OrchestraResourceIdIndex<T> = new Map();

  iterateResourceCollection(repository, resourceType)
    .map(resourceConstructor)
    .forEach((resource) => {
      map.set(resource.id, resource);
    });

  return map;
}

function extractResourcesByName<T extends OrchestraResourceBase>(
  repository: XmlElement,
  resourceType: OrchestraResourceType,
  resourceConstructor: (element: XmlElement) => T
): OrchestraResourceNameIndex<T> {
  const map: OrchestraResourceNameIndex<T> = new Map();

  iterateResourceCollection(repository, resourceType).forEach((element) => {
    const resource = resourceConstructor(element);
    map.set(resource.name, resource);
  });

  return map;
}
function extractCodeSet(element: XmlElement): OrchestraCodeSet {
  const type = 'codeSet' satisfies OrchestraResourceType;
  const idNameAbbr = extractIdNameAbbr(element);
  const scenario = extractScenario(element);
  return {
    type,
    ...idNameAbbr,
    ...scenario,
    ...createUniqueId(type, idNameAbbr.id, scenario.scenario),
    ...extractDocumentationSynopsisAndElaboration(element),
    ...extractPedigree(element),
    datatype: srsly(element.attribute('type')),
    codes: element
      .findAll('fixr:code')
      .filter(filterToDefaultScenario)
      .map((codeElement) => extractCodeSetCode(idNameAbbr.id, codeElement))
      .sort((a, b) => {
        const aSort = a.sort === undefined ? NaN : parseInt(a.sort);
        const bSort = b.sort === undefined ? NaN : parseInt(b.sort);
        return aSort - bSort;
      }),
  };
}

function extractCodeSetCode(
  codeSetId: number,
  element: XmlElement
): OrchestraCodeSetCode {
  const type = 'codeSetValue' satisfies OrchestraResourceType;
  const idNameAbbr = extractIdNameAbbr(element);
  const scenario = extractScenario(element);
  return {
    type,
    ...idNameAbbr,
    ...scenario,
    ...createUniqueId(type, idNameAbbr.id, scenario.scenario),
    ...extractDocumentationSynopsisAndElaboration(element),
    ...extractPedigree(element),
    codeSetId,
    value: element.attribute('value') || '',
    sort: element.attribute('sort'),
    group: element.attribute('group'),
  };
}

// Note: refs is initialized here empty and must be populated later
function extractComponent(element: XmlElement): OrchestraComponent {
  const type = 'component' satisfies OrchestraResourceType;
  const idNameAbbr = extractIdNameAbbr(element);
  const scenario = extractScenario(element);
  const category = element.attribute('category');

  return {
    type,
    ...idNameAbbr,
    ...scenario,
    ...createUniqueId(type, idNameAbbr.id, scenario.scenario),
    ...extractDocumentationSynopsisAndElaboration(element),
    ...extractPedigree(element),
    ...extractScenario(element),
    category,
    refs: [],
  };
}

function extractDatatype(element: XmlElement): OrchestraDatatype {
  const type = 'datatype' satisfies OrchestraResourceType;
  const name = srsly(element.attribute('name'));

  return {
    type,
    uniqueId: `${type}-${name}`,
    ...extractDocumentationSynopsisAndElaboration(element),
    ...extractPedigree(element),
    name,
    baseType: element.attribute('baseType'),
  };
}

function extractField(element: XmlElement): OrchestraField {
  const type = 'field' satisfies OrchestraResourceType;
  const idNameAbbr = extractIdNameAbbr(element);
  const scenario = extractScenario(element);

  return {
    type,
    ...idNameAbbr,
    ...scenario,
    ...createUniqueId(type, idNameAbbr.id, scenario.scenario),
    ...extractDocumentationSynopsisAndElaboration(element),
    ...extractPedigree(element),
    datatype: srsly(element.attribute('type')),
  };
}

// Note: refs is initialized here empty and must be populated later
function extractGroup(element: XmlElement): OrchestraGroup {
  const type = 'group' satisfies OrchestraResourceType;
  const idNameAbbr = extractIdNameAbbr(element);
  const scenario = extractScenario(element);
  const category = element.attribute('category');

  return {
    type,
    ...idNameAbbr,
    ...scenario,
    ...createUniqueId(type, idNameAbbr.id, scenario.scenario),
    ...extractDocumentationSynopsisAndElaboration(element),
    ...extractPedigree(element),
    category,
    refs: [],
  };
}

function extractMessage(
  element: XmlElement,
  componentIdIndex: OrchestraComponentIdIndex,
  fieldIdIndex: OrchestraFieldIdIndex,
  groupIdIndex: OrchestraGroupIdIndex
): OrchestraMessage {
  const type = 'message' satisfies OrchestraResourceType;
  const idNameAbbr = extractIdNameAbbr(element);
  const scenario = extractScenario(element);
  const msgType = element.attribute('msgType');
  const category = element.attribute('category');
  const structure = srsly(element.findOne('fixr:structure'));
  const refs = structure
    .findAll()
    .flatMap((xmlRef) =>
      extractRef(xmlRef, componentIdIndex, fieldIdIndex, groupIdIndex)
    );

  return {
    type,
    ...idNameAbbr,
    ...scenario,
    ...createUniqueId(type, idNameAbbr.id, scenario.scenario),
    ...extractDocumentationSynopsisAndElaboration(element),
    ...extractPedigree(element),
    msgType,
    category,
    refs,
  };
}

function populateAllComponentAndGroupRefs(
  repository: XmlElement,
  componentIdIndex: OrchestraComponentIdIndex,
  fieldIdIndex: OrchestraFieldIdIndex,
  groupIdIndex: OrchestraGroupIdIndex
) {
  iterateResourceCollection(repository, 'component').forEach((element) => {
    const { id } = extractIdNameAbbr(element);
    const component = srsly(componentIdIndex.get(id));

    populateRefs(
      element,
      component,
      componentIdIndex,
      fieldIdIndex,
      groupIdIndex
    );
  });

  iterateResourceCollection(repository, 'group').forEach((element) => {
    const { id } = extractIdNameAbbr(element);
    const group = srsly(groupIdIndex.get(id));

    populateRefs(element, group, componentIdIndex, fieldIdIndex, groupIdIndex);

    const numInGroupFieldRef = resolveNumInGroupField(fieldIdIndex, element);
    if (numInGroupFieldRef !== undefined) {
      group.refs.unshift({
        resource: numInGroupFieldRef,
        required: true,
      });
    }
  });
}

function resolveNumInGroupField(
  fieldIdIndex: OrchestraFieldIdIndex,
  element: XmlElement
): OrchestraField | undefined {
  const numInGroupRef = element.findOne('fixr:numInGroup');
  if (numInGroupRef === undefined) {
    return;
  }

  const numInGroupFieldId = parseInt(srsly(numInGroupRef.attribute('id')));
  return fieldIdIndex.get(numInGroupFieldId);
}

function populateRefs(
  element: XmlElement,
  hasRefs: HasRefs,
  componentIdIndex: OrchestraComponentIdIndex,
  fieldIdIndex: OrchestraFieldIdIndex,
  groupIdIndex: OrchestraGroupIdIndex
) {
  hasRefs.refs = element
    .findAll()
    .flatMap((xmlRef) =>
      extractRef(xmlRef, componentIdIndex, fieldIdIndex, groupIdIndex)
    );
}

function extractDocumentationSynopsisAndElaboration(
  element: XmlElement
): HasDocumentation {
  const documentationElements = element
    .findOne('fixr:annotation')
    ?.findAll('fixr:documentation');

  const synopsisElement =
    documentationElements
      ?.filter((element) => element.attribute('purpose') === 'SYNOPSIS')
      .map((element) => element.text()) || undefined;

  const elaborationElement =
    documentationElements
      ?.filter((element) => element.attribute('purpose') === 'ELABORATION')
      .map((element) => element.text()) || undefined;

  // Join documentation elements with two blank lines to indicate a paragraph break in Markdown
  const synopsis = synopsisElement?.join('\n\n');
  const elaboration = elaborationElement?.join('\n\n');

  return { synopsis, elaboration };
}

function extractRefDocumentation(element: XmlElement): string | undefined {
  const documentationElements = element
    .findOne('fixr:annotation')
    ?.findAll('fixr:documentation');

  return documentationElements?.map((element) => element.text()).join('\n\n');
}

function extractPedigree(element: XmlElement): HasPedigree {
  const { added, addedEP, updated, updatedEP, deprecated, deprecatedEP } =
    element.attributes();
  return { added, addedEP, updated, updatedEP, deprecated, deprecatedEP };
}

function extractScenario(element: XmlElement): HasScenario {
  const scenario = element.attribute('scenario') || DEFAULT_SCENARIO;
  return { scenario };
}

// Resource IDs in orchestra aren't necessarily unique within the spec, as they can be re-used across types and are the
// same between different "scenarios" of the same resource
function createUniqueId(
  type: OrchestraResourceType,
  id: number,
  scenario: string
): { uniqueId: string } {
  return { uniqueId: `${type}-${id}-${scenario}` };
}

function extractIdNameAbbr(element: XmlElement): IdNameAbbr {
  const attributes = element.attributes();
  const id = parseInt(attributes['id']);
  const name = attributes['name'];
  const abbrName = attributes['abbrName'];
  return { id, name, abbrName };
}

function isRequired(xmlFieldRef: XmlElement) {
  return xmlFieldRef.attribute('presence') === 'required';
}

function srsly<T>(thing: T | undefined): T {
  if (thing === undefined) {
    throw Error('Invalid Orchestra XML');
  }

  return thing;
}

function extractRef(
  xmlRef: XmlElement,
  componentIdIndex: OrchestraComponentIdIndex,
  fieldIdIndex: OrchestraFieldIdIndex,
  groupIdIndex: OrchestraGroupIdIndex
): OrchestraComponentFieldGroupRef[] {
  const refType: OrchestraResourceType | undefined = (() => {
    const tagName = xmlRef.getTagName();

    if (tagName === 'fixr:componentRef') {
      return 'component';
    } else if (tagName === 'fixr:fieldRef') {
      return 'field';
    } else if (tagName === 'fixr:groupRef') {
      return 'group';
    }

    return undefined;
  })();

  if (refType === undefined) {
    return [];
  }

  const target: OrchestraReferrable | undefined = (() => {
    const refId = parseInt(srsly(xmlRef.attribute('id')));

    if (refType === 'component') {
      return componentIdIndex.get(refId);
    } else if (refType === 'field') {
      return fieldIdIndex.get(refId);
    } else if (refType === 'group') {
      return groupIdIndex.get(refId);
    }

    return undefined;
  })();

  if (target === undefined) {
    return [];
  }

  return [
    {
      ...extractPedigree(xmlRef),
      resource: target,
      required: isRequired(xmlRef),
      documentation: extractRefDocumentation(xmlRef),
    },
  ];
}

function buildCategoryTree(
  repository: XmlElement,
  messagesIdIndex: OrchestraMessageIdIndex
): OrchestraCategoryTree {
  const categoryToMessagesMap: Map<string, OrchestraMessage[]> = new Map();

  messagesIdIndex.forEach((message) => {
    const { category } = message;
    if (!category) {
      return;
    }

    const messagesForCategory = categoryToMessagesMap.get(category);

    if (messagesForCategory === undefined) {
      categoryToMessagesMap.set(category, [message]);
    } else {
      messagesForCategory.push(message);
    }
  });

  const categoryTree: OrchestraCategoryTree = new Map();

  iterateResourceCollection(repository, 'category', 'categories')
    .filter((element) => element.attribute('componentType') === 'Message')
    .forEach((element) => {
      const section = element.attribute('section');
      const categoryName = element.attribute('name');

      if (section === undefined || categoryName === undefined) {
        return;
      }

      let categoriesInSection = categoryTree.get(section);
      if (categoriesInSection === undefined) {
        categoriesInSection = new Map();
        categoryTree.set(section, categoriesInSection);
      }

      const messagesForCategory = categoryToMessagesMap.get(categoryName) || [];
      categoriesInSection.set(categoryName, messagesForCategory);
    });

  return categoryTree;
}
