const ORCH_HUB_SPEC_LIST_PATH = `/api/orchimate.json`;

export type OrchHubApiSpec = {
  name: string;
  group: string | null;
  slug: string;
  version: string;
  path: string;
};

type SpecListApiResponse = {
  orchestraSpecs: OrchHubApiSpec[];
};

export async function fetchOrchHubSpecList(
  orchestraHubUrl: string
): Promise<OrchHubApiSpec[]> {
  const response = await fetch(`${orchestraHubUrl}${ORCH_HUB_SPEC_LIST_PATH}`);

  if (!response.ok) {
    throw new Error('Error fetching Orchestra Hub specs');
  }

  const json: SpecListApiResponse = await response.json();
  return json.orchestraSpecs;
}
