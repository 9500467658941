export type OrchestraSpecMetadata = Map<string, string>;

export type HasId = {
  id: number;
};

export type OrchestraResourceBase = {
  uniqueId: string; // scenarios mean actual id isn't really unique
  name: string;
  type: OrchestraResourceType;
};

export type HasAbbrName = {
  abbrName?: string;
};

export type HasCategory = {
  category?: string;
};

export type HasDocumentation = {
  synopsis?: string;
  elaboration?: string;
};

export type HasPedigree = {
  added?: string;
  addedEP?: string;
  updated?: string;
  updatedEP?: string;
  deprecated?: string;
  deprecatedEP?: string;
};

export type HasScenario = {
  scenario: string;
};

export type HasValue = {
  value: string;
};

export type HasRefs = {
  refs: OrchestraComponentFieldGroupRef[];
};

export type OrchestraResourceType =
  | 'category'
  | 'codeSet'
  | 'codeSetValue' // Should this be here? The rest are top-level resources
  | 'component'
  | 'datatype'
  | 'field'
  | 'group'
  | 'message';

export type OrchestraCodeSet = OrchestraResourceBase &
  HasId &
  HasDocumentation &
  HasPedigree &
  HasScenario & {
    type: 'codeSet';
    datatype: string;
    codes: OrchestraCodeSetCode[];
  };
export type OrchestraCodeSetCode = OrchestraResourceBase &
  HasId &
  HasDocumentation &
  HasPedigree &
  HasScenario &
  HasValue & {
    type: 'codeSetValue';
    codeSetId: number;
    sort?: string; // sort should actually be a number, but the xsd has it as string so we can't be certain
    group?: string;
  };
export type OrchestraComponent = OrchestraResourceBase &
  HasId &
  HasAbbrName &
  HasCategory &
  HasDocumentation &
  HasPedigree &
  HasScenario &
  HasRefs & { type: 'component' };
export type OrchestraDatatype = OrchestraResourceBase &
  HasPedigree &
  HasDocumentation & {
    type: 'datatype';
    baseType?: string;
  };
export type OrchestraField = OrchestraResourceBase &
  HasId &
  HasAbbrName &
  HasDocumentation &
  HasPedigree &
  HasScenario & { type: 'field'; datatype: string };
export type OrchestraGroup = OrchestraResourceBase &
  HasId &
  HasAbbrName &
  HasCategory &
  HasDocumentation &
  HasPedigree &
  HasScenario &
  HasRefs & { type: 'group' };
export type OrchestraMessage = OrchestraResourceBase &
  HasId &
  HasAbbrName &
  HasCategory &
  HasDocumentation &
  HasPedigree &
  HasScenario &
  HasRefs & {
    type: 'message';
    msgType?: string;
    category?: string;
  };

export type OrchestraResource =
  | OrchestraCodeSet
  | OrchestraCodeSetCode
  | OrchestraComponent
  | OrchestraDatatype
  | OrchestraField
  | OrchestraGroup
  | OrchestraMessage;

export type OrchestraReferrable =
  | OrchestraComponent
  | OrchestraField
  | OrchestraGroup;

export type OrchestraComponentFieldGroupRef = HasPedigree & {
  resource: OrchestraReferrable;
  required: boolean;
  documentation?: string;
};

export type OrchestraComponentRef = OrchestraComponentFieldGroupRef & {
  type: 'component';
  resource: OrchestraComponent;
};

export function isComponentRef(
  ref: OrchestraComponentFieldGroupRef
): ref is OrchestraComponentRef {
  return ref.resource.type === 'component';
}

export type OrchestraFieldRef = OrchestraComponentFieldGroupRef & {
  type: 'field';
  resource: OrchestraField;
};

export function isFieldRef(
  ref: OrchestraComponentFieldGroupRef
): ref is OrchestraFieldRef {
  return ref.resource.type === 'field';
}

export type OrchestraGroupRef = OrchestraComponentFieldGroupRef & {
  type: 'group';
  resource: OrchestraGroup;
};

export function isGroupRef(
  ref: OrchestraComponentFieldGroupRef
): ref is OrchestraGroupRef {
  return ref.resource.type === 'group';
}

export type OrchestraResourceIdIndex<T> = Map<number, T>;
export type OrchestraCodeSetCodeIdIndex =
  OrchestraResourceIdIndex<OrchestraCodeSetCode>;
export type OrchestraCodeSetIdIndex =
  OrchestraResourceIdIndex<OrchestraCodeSet>;
export type OrchestraComponentIdIndex =
  OrchestraResourceIdIndex<OrchestraComponent>;
export type OrchestraFieldIdIndex = OrchestraResourceIdIndex<OrchestraField>;
export type OrchestraGroupIdIndex = OrchestraResourceIdIndex<OrchestraGroup>;
export type OrchestraMessageIdIndex =
  OrchestraResourceIdIndex<OrchestraMessage>;

export type OrchestraResourceNameIndex<T> = Map<string, T>;
export type OrchestraCodeSetNameIndex =
  OrchestraResourceNameIndex<OrchestraCodeSet>;
export type OrchestraComponentNameIndex =
  OrchestraResourceNameIndex<OrchestraComponent>;
export type OrchestraDatatypeNameIndex =
  OrchestraResourceNameIndex<OrchestraDatatype>;
export type OrchestraFieldNameIndex =
  OrchestraResourceNameIndex<OrchestraField>;
export type OrchestraGroupNameIndex =
  OrchestraResourceNameIndex<OrchestraGroup>;
export type OrchestraMessageNameIndex =
  OrchestraResourceNameIndex<OrchestraMessage>;

export type OrchestraCategoryTree = Map<
  string,
  Map<string, OrchestraMessage[]>
>;

export type OrchestraUsedInIndexEntry = {
  messages: string[];
  components: string[];
  groups: string[];
};
export type OrchestraUsedInIndex = Map<string, OrchestraUsedInIndexEntry>;

export class OrchestraSpec {
  private readonly codeSetsByName: OrchestraCodeSetNameIndex;
  private readonly componentsByName: OrchestraComponentNameIndex;
  private readonly fieldsByName: OrchestraFieldNameIndex;
  private readonly groupsByName: OrchestraGroupNameIndex;
  private readonly messagesByName: OrchestraMessageNameIndex;

  private readonly codeSetsUsedInIndex: OrchestraUsedInIndex;
  private readonly componentsUsedInIndex: OrchestraUsedInIndex;
  private readonly fieldsUsedInIndex: OrchestraUsedInIndex;
  private readonly groupsUsedInIndex: OrchestraUsedInIndex;

  constructor(
    public readonly name: string,
    public readonly version: string,
    public readonly metadata: OrchestraSpecMetadata,

    private readonly codeSetCodes: OrchestraCodeSetCodeIdIndex,
    private readonly codeSets: OrchestraCodeSetIdIndex,
    private readonly components: OrchestraComponentIdIndex,
    public readonly datatypesByName: OrchestraDatatypeNameIndex,
    private readonly fields: OrchestraFieldIdIndex,
    private readonly groups: OrchestraGroupIdIndex,
    private readonly messages: OrchestraMessageIdIndex,

    private readonly categoryTree: OrchestraCategoryTree
  ) {
    this.codeSetsByName = OrchestraSpec.createNameIndex(codeSets);
    this.componentsByName = OrchestraSpec.createNameIndex(components);
    this.fieldsByName = OrchestraSpec.createNameIndex(fields);
    this.groupsByName = OrchestraSpec.createNameIndex(groups);
    this.messagesByName = OrchestraSpec.createNameIndex(messages);

    const {
      codeSetsUsedInIndex,
      componentsUsedInIndex,
      fieldsUsedInIndex,
      groupsUsedInIndex,
    } = OrchestraSpec.createUsedInIndexes(
      this.codeSetsByName,
      components,
      groups,
      messages
    );
    this.codeSetsUsedInIndex = codeSetsUsedInIndex;
    this.componentsUsedInIndex = componentsUsedInIndex;
    this.fieldsUsedInIndex = fieldsUsedInIndex;
    this.groupsUsedInIndex = groupsUsedInIndex;
  }

  private static createNameIndex<T extends OrchestraResource>(
    idIndex: OrchestraResourceIdIndex<T>
  ): OrchestraResourceNameIndex<T> {
    const map = new Map();
    idIndex.forEach((item) => map.set(item.name, item));
    return map;
  }

  /**
   * Create inverted indexes of which components, groups, and messages each code set, component, field and group appears in.
   */
  private static createUsedInIndexes(
    codeSetsByName: OrchestraCodeSetNameIndex,
    components: OrchestraComponentIdIndex,
    groups: OrchestraGroupIdIndex,
    messages: OrchestraMessageIdIndex
  ) {
    const codeSetsUsedInIndex: OrchestraUsedInIndex = new Map();
    const componentsUsedInIndex: OrchestraUsedInIndex = new Map();
    const fieldsUsedInIndex: OrchestraUsedInIndex = new Map();
    const groupsUsedInIndex: OrchestraUsedInIndex = new Map();

    this.populateUsedInIndexes(
      components,
      (entry) => entry.components,
      codeSetsByName,
      codeSetsUsedInIndex,
      componentsUsedInIndex,
      fieldsUsedInIndex,
      groupsUsedInIndex
    );
    this.populateUsedInIndexes(
      groups,
      (entry) => entry.groups,
      codeSetsByName,
      codeSetsUsedInIndex,
      componentsUsedInIndex,
      fieldsUsedInIndex,
      groupsUsedInIndex
    );
    this.populateUsedInIndexes(
      messages,
      (entry) => entry.messages,
      codeSetsByName,
      codeSetsUsedInIndex,
      componentsUsedInIndex,
      fieldsUsedInIndex,
      groupsUsedInIndex
    );

    // [AWP-870] Deduplicate and sort codeSetsUsedInIndex entries
    Array.from(codeSetsUsedInIndex.values()).forEach((entry) => {
      entry.components = Array.from(new Set(entry.components)).sort();
      entry.groups = Array.from(new Set(entry.groups)).sort();
      entry.messages = Array.from(new Set(entry.messages)).sort();
    });

    // Sort everything
    [componentsUsedInIndex, fieldsUsedInIndex, groupsUsedInIndex].forEach(
      (index) =>
        Array.from(index.values()).forEach((entry) => {
          entry.components.sort();
          entry.groups.sort();
          entry.messages.sort();
        })
    );

    return {
      codeSetsUsedInIndex,
      componentsUsedInIndex,
      fieldsUsedInIndex,
      groupsUsedInIndex,
    };
  }

  /**
   * Iterate a collection of resources, identifying the code sets, components, fields, and groups each references, populating the appropriate inverted index.
   */
  private static populateUsedInIndexes(
    resourceIdIndex: OrchestraResourceIdIndex<OrchestraResourceBase & HasRefs>,
    resourceNameListExtractor: (entry: OrchestraUsedInIndexEntry) => string[],
    codeSetsByName: OrchestraCodeSetNameIndex,
    codeSetsUsedInIndex: OrchestraUsedInIndex,
    componentsUsedInIndex: OrchestraUsedInIndex,
    fieldsUsedInIndex: OrchestraUsedInIndex,
    groupsUsedInIndex: OrchestraUsedInIndex
  ) {
    Array.from(resourceIdIndex.values()).forEach((resource) => {
      resource.refs.forEach((ref) => {
        if (isComponentRef(ref)) {
          const entry = this.createUsedInIndexEntryIfNotPresentAndGet(
            componentsUsedInIndex,
            ref.resource.name
          );
          resourceNameListExtractor(entry).push(resource.name);
        } else if (isFieldRef(ref)) {
          const entry = this.createUsedInIndexEntryIfNotPresentAndGet(
            fieldsUsedInIndex,
            ref.resource.name
          );
          resourceNameListExtractor(entry).push(resource.name);

          const codeSet = codeSetsByName.get(ref.resource.datatype);
          if (codeSet) {
            const entry = this.createUsedInIndexEntryIfNotPresentAndGet(
              codeSetsUsedInIndex,
              codeSet.name
            );
            resourceNameListExtractor(entry).push(resource.name);
          }
        } else if (isGroupRef(ref)) {
          const entry = this.createUsedInIndexEntryIfNotPresentAndGet(
            groupsUsedInIndex,
            ref.resource.name
          );
          resourceNameListExtractor(entry).push(resource.name);
        }
      });
    });
  }

  private static createUsedInIndexEntryIfNotPresentAndGet(
    index: OrchestraUsedInIndex,
    name: string
  ): OrchestraUsedInIndexEntry {
    if (!index.has(name)) {
      index.set(name, OrchestraSpec.createEmptyUsedInIndexEntry());
    }

    return index.get(name) as OrchestraUsedInIndexEntry; // Annoying cast
  }

  private static createEmptyUsedInIndexEntry() {
    return {
      components: [],
      groups: [],
      messages: [],
    };
  }

  public getCodeSetCodes(): OrchestraCodeSetCode[] {
    return Array.from(this.codeSetCodes.values());
  }

  public getCodeSetCodeById(id: number): OrchestraCodeSetCode | undefined {
    return this.codeSetCodes.get(id);
  }

  public getCodeSets(): OrchestraCodeSet[] {
    return Array.from(this.codeSets.values());
  }

  public getCodeSetById(id: number): OrchestraCodeSet | undefined {
    return this.codeSets.get(id);
  }

  public getCodeSetByName(name: string): OrchestraCodeSet | undefined {
    return this.codeSetsByName.get(name);
  }

  /**
   * Takes an {@link OrchestraCodeSet} so we can assume it exists.
   */
  public getCodeSetUsedIn(
    codeSet: OrchestraCodeSet
  ): OrchestraUsedInIndexEntry {
    return (
      this.codeSetsUsedInIndex.get(codeSet.name) ||
      OrchestraSpec.createEmptyUsedInIndexEntry()
    );
  }

  public getComponents(): OrchestraComponent[] {
    return Array.from(this.components.values());
  }

  public getComponentById(id: number): OrchestraComponent | undefined {
    return this.components.get(id);
  }

  public getComponentByName(name: string): OrchestraComponent | undefined {
    return this.componentsByName.get(name);
  }

  /**
   * Takes an {@link OrchestraComponent} so we can assume it exists.
   */
  public getComponentUsedIn(
    component: OrchestraComponent
  ): OrchestraUsedInIndexEntry {
    return (
      this.componentsUsedInIndex.get(component.name) ||
      OrchestraSpec.createEmptyUsedInIndexEntry()
    );
  }

  public getDatatypes(): OrchestraDatatype[] {
    return Array.from(this.datatypesByName.values());
  }

  public getDatatypeByName(name: string): OrchestraDatatype | undefined {
    return this.datatypesByName.get(name);
  }

  public getFields(): OrchestraField[] {
    return Array.from(this.fields.values());
  }

  public getFieldById(id: number): OrchestraField | undefined {
    return this.fields.get(id);
  }

  public getFieldByName(name: string): OrchestraField | undefined {
    return this.fieldsByName.get(name);
  }

  /**
   * Takes an {@link OrchestraField} so we can assume it exists.
   */
  public getFieldUsedIn(field: OrchestraField): OrchestraUsedInIndexEntry {
    return (
      this.fieldsUsedInIndex.get(field.name) ||
      OrchestraSpec.createEmptyUsedInIndexEntry()
    );
  }

  public getGroups(): OrchestraGroup[] {
    return Array.from(this.groups.values());
  }

  public getGroupById(id: number): OrchestraGroup | undefined {
    return this.groups.get(id);
  }

  public getGroupByName(name: string): OrchestraGroup | undefined {
    return this.groupsByName.get(name);
  }

  /**
   * Takes an {@link OrchestraGroup} so we can assume it exists.
   */
  public getGroupUsedIn(group: OrchestraGroup): OrchestraUsedInIndexEntry {
    return (
      this.groupsUsedInIndex.get(group.name) ||
      OrchestraSpec.createEmptyUsedInIndexEntry()
    );
  }

  public getMessages(): OrchestraMessage[] {
    return Array.from(this.messages.values());
  }

  public getMessageById(id: number): OrchestraMessage | undefined {
    return this.messages.get(id);
  }

  public getMessageByName(name: string): OrchestraMessage | undefined {
    return this.messagesByName.get(name);
  }

  public getCategoryTree(): OrchestraCategoryTree {
    return this.categoryTree;
  }
}
