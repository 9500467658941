import { SxProps, Theme } from '@mui/material';
import { Fragment } from 'react';

export function mergeSx(...sxProps: Array<SxProps<Theme>>): SxProps<Theme> {
  return sxProps.reduce((a, b) => [
    ...(Array.isArray(a) ? a : [a]),
    ...(Array.isArray(b) ? b : [b]),
  ]);
}

// convenient default for context functions that should never be called before initialized
export function contextShouldBeInitialized() {
  throw Error('context should be initialized');
}

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function intersperse(
  arr: Array<React.ReactNode>,
  sep: React.ReactNode
): Array<React.ReactNode> {
  const interspersed = new Array<React.ReactNode>();

  arr.forEach((element, idx) => {
    if (idx > 0) {
      interspersed.push(<Fragment key={`${idx}-sep`}>{sep}</Fragment>);
    }

    interspersed.push(<Fragment key={idx}>{element}</Fragment>);
  });

  return interspersed;
}

export function getErrorText(error: unknown) {
  return error instanceof Error
    ? error.stack || error.toString() // error.stack technically non-standard but actually always there
    : String(error);
}

// eslint complains about empty functions unless they have a comment, so this can be used where they are needed
export function noop() {
  // no-op
}
