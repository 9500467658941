import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useOrchimateConfig } from './config';
import { OrchestraSpec } from './OrchestraSpec';
import { OrchestraSpecXmlParser } from './OrchestraSpecXmlParser';
import {
  createFullOrchHubSlug,
  createSpecInfoForOrchHubSpec,
  getOrchHubSpecInfo,
} from './orchHubSpec';
import {
  isOrchHubSpecIdentifier,
  isOrchimateSpecIdentifier,
  SpecIdentifier,
  useSpecIdentifier,
} from './specIdentifier';

export const OrchestraSpecWithInfoContext =
  createContext<OrchestraSpecWithInfo | null>(null);

async function fetchXml(path: string | URL) {
  const r = await fetch(path);

  if (!r.ok) {
    throw Error(r.statusText);
  }

  return await r.text();
}

async function pickFile(): Promise<string> {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';

    input.onchange = (e) => {
      const file = (e?.target as HTMLInputElement)?.files?.[0];

      if (file === undefined) {
        return;
      }

      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');

      reader.onload = (readerEvent) => {
        const content = readerEvent?.target?.result as string;
        resolve(content);
      };
    };

    input.click();
  });
}

export type OrchestraSpecInfoBase = {
  slug: string;
  name: string;
};

export type LocalOrchestraSpecInfo = OrchestraSpecInfoBase & {
  type: 'local';
  localSpec: OrchestraSpec;
};

function createLocalOrchestraSpecSlug(id: number): string {
  return `local-${id}`;
}

function createLocalOrchestraSpecInfo(
  localSpec: OrchestraSpec,
  slug: string
): LocalOrchestraSpecInfo {
  return {
    type: 'local',
    slug,
    name: localSpec.name,
    localSpec,
  };
}

export type RemoteOrchestraSpecInfo = OrchestraSpecInfoBase & {
  type: 'remote';
  url: string;
  forceReloadCount: number;
};

export type OrchestraSpecInfo =
  | LocalOrchestraSpecInfo
  | RemoteOrchestraSpecInfo;

export type OrchestraSpecWithInfo = {
  info: OrchestraSpecInfo;
  spec: OrchestraSpec;
};

type LocalOrchestraSpecManager = {
  localSpecs: LocalOrchestraSpecInfo[];
  addNewLocalSpec: () => Promise<LocalOrchestraSpecInfo>;
  reloadLocalSpec: (
    specInfo: LocalOrchestraSpecInfo
  ) => Promise<LocalOrchestraSpecInfo>;
  deleteLocalSpec: (specInfo: LocalOrchestraSpecInfo) => void;
};

type LocalOrchestraSpecManagerState = {
  localSpecs: LocalOrchestraSpecInfo[];
  nextId: number;
};

function useLocalOrchestraSpecManager(): LocalOrchestraSpecManager {
  const [state, setState] = useState<LocalOrchestraSpecManagerState>({
    localSpecs: [],
    nextId: 1,
  });

  async function addNewLocalSpec(): Promise<LocalOrchestraSpecInfo> {
    const xml = await pickFile();
    const spec = OrchestraSpecXmlParser.parseXml(xml);

    return new Promise((resolve) => {
      setState((prevState) => {
        const slug = createLocalOrchestraSpecSlug(prevState.nextId);
        const specInfo = createLocalOrchestraSpecInfo(spec, slug);

        resolve(specInfo);

        return {
          localSpecs: prevState.localSpecs.concat(specInfo),
          nextId: prevState.nextId + 1,
        };
      });
    });
  }

  async function reloadLocalSpec(
    specInfo: LocalOrchestraSpecInfo
  ): Promise<LocalOrchestraSpecInfo> {
    const xml = await pickFile();
    const spec = OrchestraSpecXmlParser.parseXml(xml);

    return new Promise((resolve) => {
      setState((prevState) => {
        const index = prevState.localSpecs.findIndex(
          (localSpecInfo) => localSpecInfo.slug === specInfo.slug
        );
        if (index === -1) {
          throw Error(`Spec with slug ${specInfo.slug} does not exist`);
        }

        const newSpecInfo = createLocalOrchestraSpecInfo(spec, specInfo.slug);
        resolve(newSpecInfo);

        const newLocalSpecs = [...prevState.localSpecs];
        newLocalSpecs[index] = newSpecInfo;

        return {
          ...prevState,
          localSpecs: newLocalSpecs,
        };
      });
    });
  }

  function deleteLocalSpec(specInfo: LocalOrchestraSpecInfo) {
    setState((prevState) => {
      const localSpecs = state.localSpecs.filter(
        (localSpecInfo) => localSpecInfo.slug !== specInfo.slug
      );

      return {
        ...prevState,
        localSpecs,
      };
    });
  }

  return {
    localSpecs: state.localSpecs,
    addNewLocalSpec,
    reloadLocalSpec,
    deleteLocalSpec,
  };
}

export type OrchestraSpecManager = {
  ready: boolean;
  orchestraSpecs: OrchestraSpecInfo[];
  getSpecInfo: (
    specIdentifier: SpecIdentifier
  ) => OrchestraSpecInfo | undefined;
  addNewLocalSpec: () => Promise<LocalOrchestraSpecInfo>;
  reloadSpec: (specInfo: OrchestraSpecInfo) => Promise<OrchestraSpecInfo>;
  deleteLocalSpec: (specInfo: LocalOrchestraSpecInfo) => void;
};

const OrchestraManagerContext = createContext<OrchestraSpecManager | null>(
  null
);

export function useOrchestraSpecManager(): OrchestraSpecManager | null {
  return useContext(OrchestraManagerContext);
}

export function OrchestraSpecManagerProvider({ children }: PropsWithChildren) {
  const specManager = useCreateOrchestraSpecManager();

  return (
    <OrchestraManagerContext.Provider value={specManager}>
      {children}
    </OrchestraManagerContext.Provider>
  );
}

type AsyncLoadState = 'unstarted' | 'loading' | 'loaded';

function useCreateOrchestraSpecManager(): OrchestraSpecManager {
  const config = useOrchimateConfig();
  const localOrchestraSpecManager = useLocalOrchestraSpecManager();

  const [haveLoadedConfigSpecs, setHaveLoadedConfigSpecs] = useState(false);
  const [configSpecs, setConfigSpecs] = useState<RemoteOrchestraSpecInfo[]>([]);

  const [orchHubLoadState, setOrchHubLoadState] =
    useState<AsyncLoadState>('unstarted');
  const [orchHubSpecs, setOrchHubSpecs] = useState<RemoteOrchestraSpecInfo[]>(
    []
  );

  const specIdentifier = useSpecIdentifier();

  if (config && !haveLoadedConfigSpecs) {
    setConfigSpecs(
      config.orchestraSpecs.map((specLocation) => ({
        ...specLocation,
        type: 'remote',
        forceReloadCount: 0,
      }))
    );
    setHaveLoadedConfigSpecs(true);
  }

  if (config && orchHubLoadState === 'unstarted') {
    setOrchHubLoadState('loading');
    (async function () {
      setOrchHubSpecs(await getOrchHubSpecInfo(config.orchestraHub.url));
      setOrchHubLoadState('loaded');
    })();
  }

  // the orch hub list covers only the latest version of official specs, so now we handle any other spec that the user
  // may have gotten a link to from orchestra hub
  const directlyLinkedOrchHubSpec = useMemo(() => {
    if (
      !config ||
      !specIdentifier ||
      !isOrchHubSpecIdentifier(specIdentifier)
    ) {
      return [];
    }

    const specInfo = createSpecInfoForOrchHubSpec(
      config.orchestraHub.url,
      specIdentifier
    );

    // wait for the orch hub list to load, if this is an existing spec from it, use that one instead
    if (
      orchHubLoadState !== 'loaded' ||
      orchHubSpecs.find((spec) => spec.slug === specInfo.slug)
    ) {
      return [];
    }

    return [specInfo];
  }, [config, orchHubLoadState, orchHubSpecs, specIdentifier]);

  const { localSpecs } = localOrchestraSpecManager;
  const allSpecs: OrchestraSpecInfo[] = [
    ...orchHubSpecs,
    ...directlyLinkedOrchHubSpec,
    ...localSpecs,
    ...configSpecs,
  ];

  function findSpecBySlug(slug: string): OrchestraSpecInfo | undefined {
    return allSpecs.find((specInfo) => specInfo.slug === slug);
  }

  function getSpecInfo(
    specIdentifier: SpecIdentifier
  ): OrchestraSpecInfo | undefined {
    // shouldn't happen since the manager isn't marked as ready until the config is loaded
    if (!config) {
      return undefined;
    }

    if (isOrchimateSpecIdentifier(specIdentifier)) {
      return findSpecBySlug(specIdentifier.slug);
    } else if (isOrchHubSpecIdentifier(specIdentifier)) {
      return findSpecBySlug(createFullOrchHubSlug(specIdentifier));
    } else {
      // unreachable
    }
  }

  const { addNewLocalSpec, reloadLocalSpec, deleteLocalSpec } =
    localOrchestraSpecManager;

  async function reloadSpec(
    specInfo: OrchestraSpecInfo
  ): Promise<OrchestraSpecInfo> {
    if (specInfo.type === 'local') {
      return reloadLocalSpec(specInfo);
    }

    return new Promise((resolve) => {
      setConfigSpecs((prevState) => {
        return prevState.map((prevSpecInfo) => {
          if (prevSpecInfo.slug !== specInfo.slug) {
            return prevSpecInfo;
          }

          const newSpecInfo = {
            ...prevSpecInfo,
            forceReloadCount: prevSpecInfo.forceReloadCount + 1,
          };

          resolve(newSpecInfo);
          return newSpecInfo;
        });
      });
    });
  }

  return {
    ready: !!config && haveLoadedConfigSpecs && orchHubLoadState === 'loaded',
    orchestraSpecs: allSpecs,
    getSpecInfo,
    addNewLocalSpec,
    reloadSpec,
    deleteLocalSpec,
  };
}

type LoadedExternalSpecState = {
  slug: string;
  spec: OrchestraSpec;
} | null;

export function useOrchestraSpec(
  specInfo: OrchestraSpecInfo | null
): [OrchestraSpec | null, boolean] {
  const [externalSpec, setExternalSpec] =
    useState<LoadedExternalSpecState>(null);

  // only go through useEffect and state indirection if we have to fetch
  useEffect(() => {
    if (!specInfo || specInfo.type === 'local') {
      setExternalSpec(null); // don't keep a whole spec in memory for no reason
      return;
    }

    fetchXml(specInfo.url)
      .then(OrchestraSpecXmlParser.parseXml)
      .then((spec) => {
        setExternalSpec({ slug: specInfo.slug, spec });
      });
  }, [specInfo]);

  if (!specInfo) {
    return [null, false];
  } else if (specInfo.type === 'local') {
    return [specInfo.localSpec, false];
  } else {
    return [externalSpec?.spec || null, externalSpec?.slug !== specInfo.slug];
  }
}
