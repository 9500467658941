import { Link as MuiLink, LinkProps } from '@mui/material';
import { Link as RRLink, To } from 'react-router-dom';
import { ResourceSlugSpecifier, usePathHelper } from './Path';
import React, { useContext } from 'react';
import { OrchestraSpecWithInfoContext } from './OrchestraSpecManager';
import { BottomDrawerLink } from './BottomDrawer';
import { OrchestraReferrable } from './OrchestraSpec';

export type InternalLinkProps = LinkProps & {
  to: To;
};

/**
 * A simple wrapper for a MUI Link that delegates to a react-router Link.
 */
export function InternalLink(props: InternalLinkProps) {
  return (
    <MuiLink component={RRLink} {...props}>
      {props.children}
    </MuiLink>
  );
}

export function ResourceLink({
  children,
  orchestraResource,
  discardDetailPane,
  scrollToRef,
  ...linkProps
}: {
  orchestraResource: ResourceSlugSpecifier;
  discardDetailPane?: boolean;
  scrollToRef?: OrchestraReferrable;
} & Omit<InternalLinkProps, 'to'>) {
  const pathHelper = usePathHelper();
  return (
    <InternalLink
      to={pathHelper.toResource(orchestraResource, {
        discardDetailPane,
        scrollToRef,
      })}
      {...linkProps}
    >
      {children || orchestraResource.name}
    </InternalLink>
  );
}

export const DataTypeLink = ({ type }: { type?: string }) => {
  const orchestraSpec = useContext(OrchestraSpecWithInfoContext)?.spec;

  if (!type || !orchestraSpec) {
    return null;
  }

  const codeSet = orchestraSpec.getCodeSetByName(type);
  let resource: ResourceSlugSpecifier;
  if (codeSet) {
    resource = { type: 'codeSet', name: codeSet.name };
  } else {
    resource = { type: 'datatype', name: type };
  }

  return <BottomDrawerLink resource={resource} />;
};
