import {
  OrchestraSpecInfo,
  RemoteOrchestraSpecInfo,
} from './OrchestraSpecManager';
import { OrchHubSpecIdentifier } from './specIdentifier';
import { fetchOrchHubSpecList } from './orchHubApi';

export async function getOrchHubSpecInfo(
  orchestraHubUrl: string
): Promise<RemoteOrchestraSpecInfo[]> {
  const specList = await fetchOrchHubSpecList(orchestraHubUrl);

  return specList.map((spec) => ({
    type: 'remote',
    forceReloadCount: 0,
    slug: createFullOrchHubSlug({
      group: spec.group || 'official',
      slug: spec.slug,
      version: spec.version,
    }),
    name: spec.name,
    url: `${orchestraHubUrl}${spec.path}`,
  }));
}

export function createSpecInfoForOrchHubSpec(
  orchestraHubUrl: string,
  specIdentifier: OrchHubSpecIdentifier
): OrchestraSpecInfo {
  return {
    type: 'remote',
    forceReloadCount: 0,
    slug: createFullOrchHubSlug(specIdentifier),
    name: `Orchestra Hub: ${specIdentifier.slug} (${specIdentifier.version})`,
    url: createOrchHubSpecUrl(orchestraHubUrl, specIdentifier),
  };
}

export function createFullOrchHubSlug({
  group,
  slug,
  version,
}: Omit<OrchHubSpecIdentifier, 'type'>): string {
  if (group === 'official') {
    return `-/${slug}/${version}`;
  } else {
    return `r/${group}/${slug}/${version}`;
  }
}

function createOrchHubSpecUrl(
  baseUrl: string,
  { group, slug, version }: OrchHubSpecIdentifier
): string {
  const prefix = group === 'official' ? '-' : `r/${group}`;
  return `${baseUrl}/_static/specs/${prefix}/${slug}/${version}/${slug}-${version}.xml`;
}
