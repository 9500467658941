import { Link as MuiLink, LinkProps } from '@mui/material';
import MuiMarkdown from 'mui-markdown';
import { MarkdownToJSX } from 'markdown-to-jsx';

export type MarkdownProps = {
  children: string;
  forceDisplay?: 'block' | 'inline';
  overrides?: MarkdownToJSX.Overrides;
};

export function Markdown(props: MarkdownProps) {
  // The Markdown that's embedded in Orchestra XML is a bit wonky in that it is often indented,
  // which is problematic because left-indentation is significant in Markdown as it indicates when
  // text should be rendered as pre-formatted.
  //
  // For now, let's just strip all whitespace from the beginning of each line, though this could
  // cause problems if a spec wants to use a richer subset of Markdown.
  const srcNoLeadingWhitespace = props.children
    .split(/\r?\n/)
    .map((row) => row.trimStart())
    .join('\n');

  // By default, open all links in Markdown in an external window as they are coming directly from the spec
  const defaultMarkdownOverrides = {
    a: {
      component: MuiLink,
      props: { target: '_blank', rel: 'noopener noreferrer' } as LinkProps,
    },
  };

  const options: MarkdownToJSX.Options = {
    disableParsingRawHTML: true, // Without this, strings like `&lt;Parties&gt;` erroneously get turned into HTML
    overrides: props.overrides,
  };

  if (props.forceDisplay === 'block') {
    options.forceBlock = true;
  } else if (props.forceDisplay === 'inline') {
    options.forceInline = true;
  }

  options.overrides = {
    ...defaultMarkdownOverrides,
    ...options.overrides,
  };

  return <MuiMarkdown options={options}>{srcNoLeadingWhitespace}</MuiMarkdown>;
}

const markdownLinkRegex =
  /\[(?<text>[^[\]]+)\]\((?<url>https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))\)/g;

/**
 * Returns an array of {@link React.ReactNode} with alternating strings and link elements.
 *
 * Visible for testing because I don't know how to properly test this sort of thing.
 */
export function formatMarkdownLinks(value: string): React.ReactNode[] {
  const nodes: React.ReactNode[] = [];

  let cursor = 0;
  let match;
  while ((match = markdownLinkRegex.exec(value)) !== null) {
    const markdownLink = match[0];

    // Capture between the cursor and start of the match as normal text
    const beforePart = value.substring(cursor, match.index);
    if (beforePart.length !== 0) {
      nodes.push(beforePart);
    }

    const text = match.groups?.text;
    const url = match.groups?.url;

    if (text && url) {
      // Yield the link parsed from the matched markdown link
      nodes.push(
        <MuiLink
          key={`${markdownLink}_${cursor}`}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
        >
          {text}
        </MuiLink>
      );
    }

    // Advance the cursor to the end of the match
    cursor = match.index + markdownLink.length;
  }

  // Capture any remaining text as normal text
  const afterPart = value.substring(cursor, value.length);
  if (afterPart.length !== 0) {
    nodes.push(afterPart);
  }

  return nodes;
}

/**
 * Renders only the links from an input Markdown string, leaving all other text in-place.
 *
 * Returns a fragment consisting of alternating strings and link elements.
 */
export function MarkdownLinksOnly({ children }: { children: string }) {
  return <>{formatMarkdownLinks(children)}</>;
}
