import React, { useContext, useMemo } from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  useParams,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';
import { OrchimateConfigProvider, useOrchimateConfig } from './config';
import { Home, SpecHome } from './Home';
import { Layout } from './Layout';
import {
  OrchestraSpecWithInfoContext,
  useOrchestraSpec,
  useOrchestraSpecManager,
  OrchestraSpecManagerProvider,
} from './OrchestraSpecManager';
import {
  CodeSetView,
  ComponentView,
  DatatypeView,
  FieldView,
  GroupView,
  MessageView,
} from './OrchestraSpecViews';
import { Container, Skeleton, ThemeProvider, Typography } from '@mui/material';
import {
  CodeSetIndexView,
  ComponentIndexView,
  DatatypeIndexView,
  FieldIndexView,
  GroupIndexView,
  MessageIndexView,
} from './OrchestraSpecIndexViews';
import { SearchView } from './SearchView';
import { SpecSearchContextProvider, useSpecSearch } from './specSearch';
import { PathContextProvider } from './Path';
import { InternalLink } from './InternalLink';
import { ErrorPage } from './ErrorPage';
import { theme } from './theme';
import { ErrorBoundary } from 'react-error-boundary';
import { useFathom } from './fathom';
import {
  ORCH_HUB_GROUP_SPEC_ROUTE_ID,
  ORCH_HUB_OFFICIAL_SPEC_ROUTE_ID,
  ORCHIMATE_SPEC_ROUTE_ID,
} from './routing';
import { useSpecIdentifier } from './specIdentifier';

function NotFound() {
  return (
    <Container maxWidth='lg'>
      <Typography variant='h4' component='h2'>
        Not Found
      </Typography>
      <Typography variant='body1' sx={{ mt: 2 }}>
        <InternalLink to='/'>&raquo; Return home</InternalLink>
      </Typography>
    </Container>
  );
}

function HomeRoute() {
  return <Home />;
}

function SpecHomeRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  return <SpecHome orchestraSpec={spec} />;
}

function CodeSetRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { codeSetName } = useParams();
  const codeSet = codeSetName ? spec?.getCodeSetByName(codeSetName) : undefined;
  const usedIn = codeSet && spec?.getCodeSetUsedIn(codeSet);
  return <CodeSetView codeSet={codeSet} usedIn={usedIn} />;
}

function CodeSetIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  return <CodeSetIndexView codeSets={spec?.getCodeSets()} />;
}

function ComponentRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { componentName } = useParams();
  const component = componentName
    ? spec?.getComponentByName(componentName)
    : undefined;
  const usedIn = component && spec?.getComponentUsedIn(component);
  return <ComponentView component={component} usedIn={usedIn} />;
}

function ComponentIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  return <ComponentIndexView components={spec?.getComponents()} />;
}

function DatatypeRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { datatypeName } = useParams();
  const datatype = datatypeName
    ? spec?.getDatatypeByName(datatypeName)
    : undefined;
  return <DatatypeView datatype={datatype} />;
}

function DatatypeIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  return <DatatypeIndexView datatypes={spec?.getDatatypes()} />;
}

function FieldRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { fieldName } = useParams();
  const field = fieldName ? spec?.getFieldByName(fieldName) : undefined;
  const usedIn = field && spec?.getFieldUsedIn(field);
  return <FieldView field={field} usedIn={usedIn} />;
}

function FieldIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  return <FieldIndexView fields={spec?.getFields()} />;
}

function GroupRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { groupName } = useParams();
  const group = groupName ? spec?.getGroupByName(groupName) : undefined;
  const usedIn = group && spec?.getGroupUsedIn(group);
  return <GroupView group={group} usedIn={usedIn} />;
}

function GroupIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  return <GroupIndexView groups={spec?.getGroups()} />;
}

function MessageRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { messageName } = useParams();
  const message = messageName ? spec?.getMessageByName(messageName) : undefined;
  return <MessageView message={message} />;
}

function MessageIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  return <MessageIndexView messages={spec?.getMessages()} />;
}

function SearchRoute() {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');
  const specSearch = useSpecSearch();

  return <SearchView specSearch={specSearch} searchQuery={searchQuery || ''} />;
}

// Parent route to all routes to provide a spec manager
function OuterRoute() {
  const config = useOrchimateConfig();
  useFathom(config?.fathom);

  return (
    <OrchestraSpecManagerProvider>
      <Outlet />
    </OrchestraSpecManagerProvider>
  );
}

// Parent route for routes in the context of a spec
function SlugRoute() {
  const orchestraSpecManager = useOrchestraSpecManager();
  const specIdentifier = useSpecIdentifier();

  const specInfo =
    (specIdentifier && orchestraSpecManager?.getSpecInfo(specIdentifier)) ||
    null;
  const [orchestraSpec, loadingOrchestraSpec] = useOrchestraSpec(specInfo);

  const orchestraSpecContext = useMemo(
    () =>
      orchestraSpec && specInfo
        ? {
            info: specInfo,
            spec: orchestraSpec,
          }
        : null,
    [specInfo, orchestraSpec]
  );

  if (!orchestraSpecManager?.ready || loadingOrchestraSpec) {
    // If the spec manager is not ready, show a skeleton until it is
    return (
      <Layout noOutlet>
        <Typography variant='h4' component='h2'>
          <Skeleton />
        </Typography>
      </Layout>
    );
  }

  if (!specInfo) {
    // If the spec manager is ready but we didn't find a spec for the given slug, that's a 404
    return (
      <Layout noOutlet>
        <NotFound />
      </Layout>
    );
  }

  return (
    <OrchestraSpecWithInfoContext.Provider value={orchestraSpecContext}>
      <SpecSearchContextProvider>
        <PathContextProvider>
          <Layout />
        </PathContextProvider>
      </SpecSearchContextProvider>
    </OrchestraSpecWithInfoContext.Provider>
  );
}

const ErrorRoute = () => {
  const error = useRouteError();
  return (
    <Layout>
      <ErrorPage error={error} />
    </Layout>
  );
};

function getSingleSpecRoutes() {
  return (
    <>
      <Route path='codeSets'>
        <Route path=':codeSetName' element={<CodeSetRoute />} />
        <Route index element={<CodeSetIndexRoute />} />
      </Route>
      <Route path='components'>
        <Route path=':componentName' element={<ComponentRoute />} />
        <Route index element={<ComponentIndexRoute />} />
      </Route>
      <Route path='datatypes'>
        <Route path=':datatypeName' element={<DatatypeRoute />} />
        <Route index element={<DatatypeIndexRoute />} />
      </Route>
      <Route path='fields'>
        <Route path=':fieldName' element={<FieldRoute />} />
        <Route index element={<FieldIndexRoute />} />
      </Route>
      <Route path='groups'>
        <Route path=':groupName' element={<GroupRoute />} />
        <Route index element={<GroupIndexRoute />} />
      </Route>
      <Route path='messages'>
        <Route path=':messageName' element={<MessageRoute />} />
        <Route index element={<MessageIndexRoute />} />
      </Route>
      <Route path='search' element={<SearchRoute />} />
      <Route path='*' element={<NotFound />} />
      <Route index element={<SpecHomeRoute />} />
    </>
  );
}

function Router() {
  const routes = createRoutesFromElements(
    <Route path='/' element={<OuterRoute />} errorElement={<ErrorRoute />}>
      <Route
        id={ORCH_HUB_OFFICIAL_SPEC_ROUTE_ID}
        path='/-/:slug/:version'
        element={<SlugRoute />}
      >
        {getSingleSpecRoutes()}
      </Route>
      <Route
        id={ORCH_HUB_GROUP_SPEC_ROUTE_ID}
        path='/r/:orchHubGroup/:slug/:version'
        element={<SlugRoute />}
      >
        {getSingleSpecRoutes()}
      </Route>
      <Route id={ORCHIMATE_SPEC_ROUTE_ID} path='/:slug' element={<SlugRoute />}>
        {getSingleSpecRoutes()}
      </Route>
      <Route
        index
        element={
          <Layout>
            <HomeRoute />
          </Layout>
        }
      />
      <Route
        path='*'
        element={
          <Layout>
            <NotFound />
          </Layout>
        }
      />
    </Route>
  );

  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
}

export function App() {
  return (
    <ErrorBoundary fallbackRender={({ error }) => <ErrorPage error={error} />}>
      <ThemeProvider theme={theme}>
        <OrchimateConfigProvider>
          <Router />
        </OrchimateConfigProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
