import React, { PropsWithChildren, ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Link as MuiLink,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import { RefTable } from './RefTable';

import {
  HasDocumentation,
  OrchestraCodeSet,
  OrchestraCodeSetCode,
  OrchestraComponent,
  OrchestraDatatype,
  OrchestraField,
  OrchestraGroup,
  OrchestraMessage,
  OrchestraReferrable,
  OrchestraResource,
  OrchestraResourceType,
  OrchestraUsedInIndexEntry,
} from './OrchestraSpec';
import { Markdown } from './Markdown';
import { useIsInBottomDrawer } from './BottomDrawer';
import { DataTypeLink, ResourceLink } from './InternalLink';
import { OpenInFull } from '@mui/icons-material';

function HeaderAndDocsContainer(props: PropsWithChildren) {
  return <Box maxWidth='lg'>{props.children}</Box>;
}

function ResourceViewSkeleton() {
  return (
    <Box>
      <HeaderAndDocsContainer>
        <ViewHeader
          type={<Skeleton />}
          title={<Skeleton />}
          miniTableEntries={[
            {
              key: 'entry1',
              value: <Skeleton />,
              keyRenderer: () => <Skeleton />,
            },
            {
              key: 'entry2',
              value: <Skeleton />,
              keyRenderer: () => <Skeleton />,
            },
          ]}
        />

        {renderDocumentation(undefined)}
      </HeaderAndDocsContainer>

      <Skeleton component={Box} />
    </Box>
  );
}

export function ComponentView({
  component,
  usedIn,
}: {
  component?: OrchestraComponent;
  usedIn?: OrchestraUsedInIndexEntry;
}) {
  if (!component || !usedIn) {
    return <ResourceViewSkeleton />;
  }

  return (
    <Box>
      <HeaderAndDocsContainer>
        <ViewHeader
          type='Component'
          title={component.name}
          miniTableEntries={[
            { key: 'ID', value: component.id },
            { key: 'Abbr Name', value: component.abbrName },
          ]}
        />

        {renderDocumentation(component)}
      </HeaderAndDocsContainer>

      <RefTable hasRefs={component} />

      <UsedIn usedIn={usedIn} from={component} />
    </Box>
  );
}

export function GroupView({
  group,
  usedIn,
}: {
  group?: OrchestraGroup;
  usedIn?: OrchestraUsedInIndexEntry;
}) {
  if (!group || !usedIn) {
    return <ResourceViewSkeleton />;
  }

  return (
    <Box>
      <HeaderAndDocsContainer>
        <ViewHeader
          type='Group'
          title={group.name}
          miniTableEntries={[
            { key: 'ID', value: group.id },
            { key: 'Abbr Name', value: group.abbrName },
          ]}
        />

        {renderDocumentation(group)}
      </HeaderAndDocsContainer>

      <RefTable hasRefs={group} />

      <UsedIn usedIn={usedIn} from={group} />
    </Box>
  );
}

export function MessageView({ message }: { message?: OrchestraMessage }) {
  if (!message) {
    return <ResourceViewSkeleton />;
  }

  const title = (() => {
    if (message.msgType !== undefined) {
      return `${message.name} (${message.msgType})`;
    } else {
      return message.name;
    }
  })();

  return (
    <Box>
      <HeaderAndDocsContainer>
        <ViewHeader
          type='Message'
          title={title}
          miniTableEntries={[
            { key: 'ID', value: message.id },
            { key: 'Abbr Name', value: message.abbrName },
          ]}
        />

        {renderDocumentation(message)}
      </HeaderAndDocsContainer>

      <RefTable hasRefs={message} />
    </Box>
  );
}

export function FieldView({
  field,
  usedIn,
}: {
  field?: OrchestraField;
  usedIn?: OrchestraUsedInIndexEntry;
}) {
  if (!field || !usedIn) {
    return <ResourceViewSkeleton />;
  }

  return (
    <Box>
      <ViewHeader
        type='Field'
        title={field.name}
        resourceForTitleLink={field}
        miniTableEntries={[
          { key: 'ID (tag)', value: field.id },
          { key: 'Abbr Name', value: field.abbrName },
          {
            key: 'Type',
            value: <DataTypeLink type={field.datatype} />,
          },
        ]}
      />

      {renderDocumentation(field)}

      <Divider sx={{ my: 2 }} />
      <UsedIn usedIn={usedIn} from={field} />
    </Box>
  );
}

function CodeSetCodeTableContent({
  group,
  codes,
}: {
  group?: string;
  codes: OrchestraCodeSetCode[];
}) {
  return (
    <>
      <TableHead>
        {group !== null && (
          <TableRow>
            <TableCell align='center' colSpan={4}>
              {group}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Value</TableCell>
          <TableCell>Synopsis</TableCell>
          <TableCell>Elaboration</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {codes.map((code) => (
          <TableRow key={code.id}>
            <TableCell>{code.name}</TableCell>
            <TableCell>{code.value}</TableCell>
            <TableCell>
              {code.synopsis && <Markdown>{code.synopsis}</Markdown>}
            </TableCell>
            <TableCell>
              {code.elaboration && <Markdown>{code.elaboration}</Markdown>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

export function CodeSetView({
  codeSet,
  usedIn,
}: {
  codeSet?: OrchestraCodeSet;
  usedIn?: OrchestraUsedInIndexEntry;
}) {
  if (!codeSet || !usedIn) {
    return <ResourceViewSkeleton />;
  }

  const codesWithNoGroup: OrchestraCodeSetCode[] = [];
  const codesByGroup: Map<string, OrchestraCodeSetCode[]> = new Map();

  codeSet.codes.forEach((code) => {
    if (code.group === undefined) {
      codesWithNoGroup.push(code);
    } else {
      const codesForGroup = codesByGroup.get(code.group);

      if (codesForGroup === undefined) {
        codesByGroup.set(code.group, [code]);
      } else {
        codesForGroup.push(code);
      }
    }
  });

  return (
    <Box>
      <ViewHeader
        type='Code Set'
        title={codeSet.name}
        resourceForTitleLink={codeSet}
        miniTableEntries={[
          { key: 'ID', value: codeSet.id },
          {
            key: 'Type',
            value: <DataTypeLink type={codeSet.datatype} />,
          },
        ]}
      />

      {renderDocumentation(codeSet)}

      <Table>
        {codesWithNoGroup.length > 0 && (
          <CodeSetCodeTableContent codes={codesWithNoGroup} />
        )}
        {Array.from(codesByGroup.keys()).map((group) => (
          <CodeSetCodeTableContent
            key={group}
            group={group}
            codes={codesByGroup.get(group) || []}
          />
        ))}
      </Table>

      <UsedIn usedIn={usedIn} />
    </Box>
  );
}

export function DatatypeView({ datatype }: { datatype?: OrchestraDatatype }) {
  if (!datatype) {
    return <ResourceViewSkeleton />;
  }

  return (
    <Box>
      <ViewHeader
        type='Datatype'
        title={datatype.name}
        resourceForTitleLink={datatype}
        miniTableEntries={[
          {
            key: 'Base Type',
            value: (
              <>
                {datatype.baseType === undefined && (
                  <Typography
                    color='grey.500'
                    fontSize='inherit'
                    sx={{ fontStyle: 'italic' }}
                  >
                    none
                  </Typography>
                )}
                {datatype.baseType !== undefined && (
                  <DataTypeLink type={datatype.baseType} />
                )}
              </>
            ),
          },
        ]}
      />

      {renderDocumentation(datatype)}
    </Box>
  );
}

function renderDocumentation(hasDocumentation?: HasDocumentation) {
  let content = <Skeleton component='p' />;

  if (hasDocumentation) {
    const { synopsis, elaboration } = hasDocumentation;
    content = (
      <>
        {synopsis && <Synopsis synopsis={synopsis} />}
        {elaboration && <Elaboration elaboration={elaboration} />}
      </>
    );
  }

  return <Box sx={{ marginTop: 4, marginBottom: 2 }}>{content}</Box>;
}

function Synopsis({ synopsis }: { synopsis: string }) {
  return (
    <Box sx={{ my: 2 }}>
      <Markdown>{synopsis}</Markdown>
    </Box>
  );
}

function Elaboration({ elaboration }: { elaboration: string }) {
  const [show, setShow] = useState(false);

  let content = null;
  if (show) {
    content = (
      <Box sx={{ my: 2, p: 2, border: '1px dashed grey' }}>
        <Markdown>{elaboration}</Markdown>
      </Box>
    );
  }

  return (
    <>
      {content}
      <MuiLink component={Button} onClick={() => setShow(!show)}>
        {show ? <span>&laquo; Less</span> : <span>&raquo; More</span>}
      </MuiLink>
    </>
  );
}

type MiniTableEntry = {
  key: string;
  value: React.ReactNode;

  // Defaults to (key) => key
  keyRenderer?: (key: string) => React.ReactNode;
};

type ViewHeaderProps = {
  type: React.ReactNode;
  title: ReactNode;
  resourceForTitleLink?: OrchestraResource;
  // For convenience, if value is undefined, its row will not be shown
  miniTableEntries: (Omit<MiniTableEntry, 'value'> & {
    value?: React.ReactNode;
  })[];
};

function ViewHeader({
  type,
  title,
  resourceForTitleLink,
  miniTableEntries,
}: ViewHeaderProps) {
  const inBottomDrawer = useIsInBottomDrawer();

  const maybeLinkedTitle =
    inBottomDrawer && resourceForTitleLink ? (
      <ResourceLink
        data-testid='expand-to-main-window-link'
        orchestraResource={resourceForTitleLink}
        discardDetailPane={true}
        sx={{
          // remove primary link styling
          color: 'text.primary',
          textDecoration: 'none',
          // on hover, gray a bit and highlight button
          '&:hover': {
            color: 'text.secondary',
            button: {
              backgroundColor: 'action.hover',
            },
          },
        }}
      >
        <Tooltip
          title='Expand to main window'
          placement='right'
          enterDelay={500}
        >
          <span>
            {title}
            <IconButton sx={{ marginLeft: 1 }}>
              <OpenInFull />
            </IconButton>
          </span>
        </Tooltip>
      </ResourceLink>
    ) : (
      title
    );

  // Filter-out entries with undefined value
  const presentMiniTableEntries: MiniTableEntry[] = miniTableEntries.flatMap(
    (entry) => {
      if (entry.value === undefined) {
        return [];
      }

      return [
        {
          ...entry,
          value: entry.value, // Convince TypeScript that value is not undefined
        },
      ];
    }
  );

  return (
    <>
      <Typography variant='h5' component={'span'} color='grey.600'>
        {type}
      </Typography>
      <Typography
        data-testid='view-header-title'
        variant='h4'
        component={inBottomDrawer ? 'h4' : 'h2'}
        sx={{ my: 1 }}
      >
        {maybeLinkedTitle}
      </Typography>

      {presentMiniTableEntries.length > 0 && (
        <ViewHeaderMiniTable entries={presentMiniTableEntries} />
      )}
    </>
  );
}

type ViewHeaderMiniTableProps = {
  entries: MiniTableEntry[];
};

function ViewHeaderMiniTable({ entries }: ViewHeaderMiniTableProps) {
  return (
    <TableContainer>
      <Table sx={{ width: '300px' }} size='small'>
        <TableBody>
          {entries.map(({ key, value, keyRenderer }) => (
            <TableRow key={key}>
              <TableCell sx={{ width: '150px' }}>
                {keyRenderer ? keyRenderer(key) : key}
              </TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function UsedIn({
  usedIn,
  from,
}: {
  usedIn: OrchestraUsedInIndexEntry;
  from?: OrchestraReferrable;
}) {
  return (
    <Box sx={{ mt: 2 }}>
      {usedIn.components.length > 0 && (
        <UsedInEntry type='component' names={usedIn.components} from={from} />
      )}
      {usedIn.groups.length > 0 && (
        <UsedInEntry type='group' names={usedIn.groups} from={from} />
      )}
      {usedIn.messages.length > 0 && (
        <UsedInEntry type='message' names={usedIn.messages} from={from} />
      )}
    </Box>
  );
}

const UsedInList = styled('ul')({
  display: 'inline',
  paddingLeft: 0,
  listStyle: 'none',
  li: {
    display: 'inline',
    '&:before': {
      content: '", "',
    },
    '&:first-of-type:before': {
      content: 'normal',
    },
  },
});

function UsedInEntry({
  type,
  names,
  from,
}: {
  type: Extract<OrchestraResourceType, 'component' | 'group' | 'message'>;
  names: string[];
  from?: OrchestraReferrable;
}) {
  return (
    <Typography
      variant='body2'
      component='div'
      sx={{ my: 1 }}
      data-testid='used-in-entry'
    >
      Used in {type}s:{' '}
      <UsedInList>
        {names.map((name) => (
          <li key={name}>
            <ResourceLink
              orchestraResource={{ type, name }}
              scrollToRef={from}
            />
          </li>
        ))}
      </UsedInList>
    </Typography>
  );
}
