import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as RRLink } from 'react-router-dom';

import { OrchestraMessage, OrchestraSpec } from './OrchestraSpec';
import { usePathHelper } from './Path';

type CategoryProps = {
  name: string;
  open: boolean;
  messages: OrchestraMessage[];
  onClick?: () => void;
};

function Category({
  name: categoryName,
  open,
  messages,
  onClick,
}: CategoryProps) {
  const pathHelper = usePathHelper();
  messages.sort((message1, message2) =>
    message1.name.localeCompare(message2.name)
  );

  const entries = messages.map((message) => (
    <ListItemButton
      key={message.id}
      component={RRLink}
      to={pathHelper.toResource(message)}
      sx={{ pl: 4 }}
    >
      <ListItemIcon>
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText primary={message.name} />
    </ListItemButton>
  ));

  return (
    <>
      <ListItemButton onClick={onClick}>
        <ListItemText primary={categoryName} sx={{ pl: 2 }} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List component='div' dense disablePadding>
          <Divider />
          {entries.length > 0 ? (
            entries
          ) : (
            <ListItemButton>
              <ListItemText secondary={<em>No messages</em>} sx={{ pl: 4 }} />
            </ListItemButton>
          )}
          <Divider />
        </List>
      </Collapse>
    </>
  );
}

type SectionProps = {
  name: string;
  open: boolean;
  categories: Map<string, OrchestraMessage[]>;
  onClick?: () => void;
};

function Section({
  name: sectionName,
  open,
  categories,
  onClick,
}: SectionProps) {
  const [showCategory, setShowCategory] = useState<string | null>(null);

  useEffect(() => {
    if (!open) {
      setShowCategory(null);
    }
  }, [open]);

  function onCategoryClick(categoryName: string) {
    if (showCategory === categoryName) {
      setShowCategory(null);
    } else {
      setShowCategory(categoryName);
    }
  }

  const categoryNames = Array.from(categories.keys());

  const entries = categoryNames.map((categoryName) => (
    <Category
      key={categoryName}
      name={categoryName}
      open={showCategory === categoryName}
      messages={categories.get(categoryName) || []}
      onClick={() => onCategoryClick(categoryName)}
    />
  ));

  return (
    <>
      <ListItemButton onClick={onClick}>
        <ListItemText primary={sectionName} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List component='div' dense disablePadding>
          <Divider />
          {entries.length > 0 ? (
            entries
          ) : (
            <ListItemButton>
              <ListItemText secondary={<em>No categories</em>} sx={{ pl: 2 }} />
            </ListItemButton>
          )}
          <Divider />
        </List>
      </Collapse>
    </>
  );
}

type MessageLayoutsProps = {
  orchestraSpec: OrchestraSpec | null;
};

export function MessageLayouts(props: MessageLayoutsProps) {
  const [showSection, setShowSection] = useState<string | null>(null);

  const categoryTree = props.orchestraSpec?.getCategoryTree();
  if (!categoryTree || categoryTree.size === 0) {
    return <></>;
  }

  const sectionNames = Array.from(categoryTree.keys());

  function onSectionClick(sectionName: string) {
    if (showSection === sectionName) {
      setShowSection(null);
    } else {
      setShowSection(sectionName);
    }
  }

  const entries = sectionNames.map((sectionName) => (
    <Section
      key={sectionName}
      name={sectionName}
      open={showSection === sectionName}
      categories={categoryTree.get(sectionName) || new Map()}
      onClick={() => onSectionClick(sectionName)}
    />
  ));

  return (
    <>
      <Typography sx={{ ml: 2, mt: 2 }} variant='h6' component='div'>
        Message Layouts
      </Typography>
      <List component='div' dense>
        {entries}
      </List>
      <Divider sx={{ mt: 1 }} />
    </>
  );
}
