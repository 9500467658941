import React, { MouseEventHandler } from 'react';
import { Email, EmailProps } from 'react-obfuscate-email';
import { Link } from '@mui/material';
import { trackEmailLinkClick } from './fathomEvents';

type EmailTemplateNameProps = {
  templateName?: string;
};

type ObfuscatedEmailProps = EmailProps & EmailTemplateNameProps;

// This serves to clear a React/MUI warning stemming from MUI wanting a ref to elements passed as `component`, but does
// not actually solve the problem as the ref is simply dropped. The solution is likely to send a change upstream to
// react-obfuscate-email to expose the ref of the underlying HTMLAnchorElement using React.forwardRef.
export const ObfuscatedEmail = React.forwardRef<
  HTMLAnchorElement,
  ObfuscatedEmailProps
>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props, _ref) => {
    const { templateName, ...emailProps } = props;

    let onClick: MouseEventHandler | undefined;
    if (templateName) {
      onClick = () => trackEmailLinkClick(templateName);
    }

    return <Email {...emailProps} onClick={onClick} />;
  }
);

type ObfuscatedEmailLinkProps = Omit<ObfuscatedEmailProps, 'ref'>;

export const ObfuscatedEmailLink = (props: ObfuscatedEmailLinkProps) => {
  return <Link component={ObfuscatedEmail} {...props} />;
};
