import { Container, Typography } from '@mui/material';
import React from 'react';
import { ObfuscatedEmailLink } from './ObfuscatedEmail';
import { reportDefectEmailProps } from './getInTouchEmailTemplates';
import { InternalLink } from './InternalLink';

export function ErrorPage({ error }: { error: unknown }) {
  return (
    <Container maxWidth='lg'>
      <Typography variant='h4' component='h2'>
        Unexpected Error
      </Typography>
      <Typography variant='body1' sx={{ mt: 2 }}>
        Please try again or{' '}
        <ObfuscatedEmailLink {...reportDefectEmailProps(error)}>
          report this error to us
        </ObfuscatedEmailLink>
        .
      </Typography>
      <Typography variant='body1' sx={{ mt: 2 }}>
        <InternalLink to='/'>&raquo; Return home</InternalLink>
      </Typography>
    </Container>
  );
}
