const getMetaTag = (name: string): string | null => {
  return (
    document.head
      .querySelector(`meta[name="${name}"]`)
      ?.getAttribute('content') || null
  );
};

let cachedOrchimateVersion;
export const getOrchimateVersion = () => {
  cachedOrchimateVersion ||= getMetaTag('orchimate-version');
  return cachedOrchimateVersion;
};
