import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  UnfoldLess,
  UnfoldMore,
} from '@mui/icons-material';

import {
  HasRefs,
  isComponentRef,
  isFieldRef,
  isGroupRef,
  OrchestraComponent,
  OrchestraComponentFieldGroupRef,
  OrchestraComponentRef,
  OrchestraFieldRef,
  OrchestraGroup,
  OrchestraGroupRef,
  OrchestraResourceType,
} from './OrchestraSpec';
import { BottomDrawerLink } from './BottomDrawer';
import { DataTypeLink, ResourceLink } from './InternalLink';
import { Markdown } from './Markdown';
import { formatPedigree } from './OrchestraSpecFormatters';
import { contextShouldBeInitialized } from './utils';
import { CommonProps } from '@mui/material/OverridableComponent';
import {
  TableSorter,
  SortableTableColumn,
  useSorter,
  SortableColumns,
  fieldComparator,
  stringComparator,
  numberComparator,
  nullsLast,
} from './tableSort';

const defaultSorter: TableSorter<OrchestraComponentFieldGroupRef> = (refs) =>
  refs;
const SorterContext = createContext(defaultSorter);

// using an object here to wrap the value so that we can force updates with ever click by providing a new object ref
type ForcedExpansionState = { expanded: boolean | null; reset: () => void };
const defaultForcedExpansionState = {
  expanded: null,
  reset: contextShouldBeInitialized,
};
const ForcedExpansionStateContext = createContext<ForcedExpansionState>(
  defaultForcedExpansionState
);

type RefTableProps = {
  hasRefs?: HasRefs;
};

export function RefTable({ hasRefs }: RefTableProps) {
  const [forcedExpansionState, setForcedExpansionState] =
    useState<ForcedExpansionState>({
      ...defaultForcedExpansionState,
      reset: () =>
        setForcedExpansionState((prevState) => ({
          ...prevState,
          expanded: null,
        })),
    });

  const columns: Array<SortableTableColumn<OrchestraComponentFieldGroupRef>> =
    useMemo(
      () => [
        {
          name: 'Field',
          sortable: true,
          comparator: (a, b) => {
            const typeSort: { [k in OrchestraResourceType]?: number } = {
              field: 0,
              component: 1,
              group: 2,
            };
            return (
              nullsLast(numberComparator)(
                typeSort[a.resource.type],
                typeSort[b.resource.type]
              ) || a.resource.id - b.resource.id
            );
          },
          sx: { width: '210px' },
          rightIcons: (
            <>
              <Tooltip title='Expand All' placement='top'>
                <IconButton
                  data-testid='expand-all-button'
                  size='small'
                  edge='end'
                  onClick={() =>
                    setForcedExpansionState((state) => ({
                      ...state,
                      expanded: true,
                    }))
                  }
                >
                  <UnfoldMore />
                </IconButton>
              </Tooltip>
              <Tooltip title='Collapse All' placement='top'>
                <IconButton
                  data-testid='collapse-all-button'
                  size='small'
                  edge='start'
                  onClick={() =>
                    setForcedExpansionState((state) => ({
                      ...state,
                      expanded: false,
                    }))
                  }
                >
                  <UnfoldLess />
                </IconButton>
              </Tooltip>
            </>
          ),
        },
        {
          name: 'Field Name',
          sortable: true,
          comparator: fieldComparator(
            (ref) => ref.resource.name,
            stringComparator
          ),
          sx: { width: '300px' },
        },
        { name: 'Type', sx: { width: '250px' } },
        { name: "Req'd", sx: { width: '75px' } },
        { name: 'Comments', sx: { width: 'auto' } },
        { name: 'Pedigree', sx: { width: '200px' } },
      ],
      [setForcedExpansionState]
    );

  const { sort, setSort, sorter } = useSorter({ columns });

  return (
    <ForcedExpansionStateContext.Provider value={forcedExpansionState}>
      <SorterContext.Provider value={sorter}>
        <TableContainer>
          <Table
            sx={{
              height: '100%',
              minWidth: '1200px',
              tableLayout: 'fixed',
              th: { padding: 1 },
              td: { height: '100%', padding: 0 },
              '.ref-table-cell-content': {
                height: '100%',
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                '&.ref-table-id-cell-content': {
                  paddingLeft: 1.5,
                },
              },
              '.ref-table-nested-level-even': {
                // color the row itself with the last level's color. the cells themselves will be this row's color,
                // but this allows any padding to show the underlying color
                backgroundColor: oddLevelColor,
                '.ref-table-cell-content': {
                  backgroundColor: evenLevelColor,
                },
                '&:not(.ref-table-last-of-nested-set)': {
                  td: {
                    backgroundColor: evenLevelColor,
                  },
                },
                // don't show a border between what is now the Component header and its fields.
                // for firefox, set the border to the box's color, because 'none' gives a white gap
                '&.ref-table-row-open > td': {
                  borderBottomColor: evenLevelColor,
                },
              },
              '.ref-table-nested-level-odd': {
                backgroundColor: evenLevelColor,
                '.ref-table-cell-content': {
                  backgroundColor: oddLevelColor,
                },
                '&:not(.ref-table-last-of-nested-set)': {
                  td: {
                    backgroundColor: oddLevelColor,
                  },
                },
                '&.ref-table-row-open > td': {
                  borderBottomColor: oddLevelColor,
                },
              },
              // in cases where we're ending two nested sets, give some space at the bottom. this space will
              // be level n-1 colored, since it's taking the color from the row itself (not the content cells).
              // also add an :after element with a border, which marks off the end of the inner set
              '.ref-table-last-of-nested-set.ref-table-parent-last-of-nested-set':
                {
                  td: {
                    paddingBottom: 2,
                    '&:after': {
                      borderBottom: 1,
                      borderBottomColor: 'grey.300',
                      content: '""',
                      display: 'block',
                    },
                  },
                },
              '.ref-table-last-of-nested-set .ref-table-cell-content': {
                paddingBottom: 2,
              },
              '.ref-table-gutter-cell': {
                paddingY: '0',
                paddingLeft: '0',
              },
              '.ref-table-gutter-cell-content': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingLeft: '0',
                '&.ref-table-component-button-cell': {
                  button: {
                    color: 'inherit',
                    textTransform: 'none',
                  },
                },
              },
              '.ref-table-gutter-cell-container': {
                display: 'flex',
                // on the left side, have these starting on the left. on the right, opposite.
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                height: '100%',
                '&.ref-table-gutter-cell-container-right': {
                  flexDirection: 'row-reverse',
                },
              },
              '.ref-table-gutter-cell-content-container': {
                padding: 0,
                flexGrow: 1,
              },
              '.ref-table-indentation-cell': {
                width: (theme) => theme.spacing(2),
                flexShrink: 0,
                // negative margin to hide existing cell border and provide some visual indentation
                marginTop: '-1px',
                marginBottom: '-1px',
                '&:nth-of-type(even)': { backgroundColor: evenLevelColor },
                '&:nth-of-type(odd)': { backgroundColor: oddLevelColor },
              },
              '.ref-table-last-of-nested-set .ref-table-indentation-cell': {
                marginBottom: 0,
              },
              '.ref-table-last-of-nested-set.ref-table-parent-last-of-nested-set .ref-table-indentation-cell':
                {
                  // last el is content, 2nd to last is border we want to show (so no margin),
                  // 3rd to last is border of extra padding for parent that we want to show (32px down),
                  // 4th on we should cover up the border (32 padding + 1 border)
                  '&:nth-last-of-type(n+3)': { marginBottom: '-17px' },
                  '&:nth-last-of-type(3)': { marginBottom: '-16px' },
                },
              '.ref-table-link-cell': {
                overflowWrap: 'anywhere',
                button: { textAlign: 'left' },
              },
            }}
          >
            <TableHead>
              <TableRow>
                <SortableColumns
                  columns={columns}
                  sort={sort}
                  setSort={setSort}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {hasRefs ? (
                <RefTableRows hasRefs={hasRefs} />
              ) : (
                <TableRow>
                  <TableCell colSpan={100}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </SorterContext.Provider>
    </ForcedExpansionStateContext.Provider>
  );
}

const evenLevelColor = 'background.default';
const oddLevelColor = 'background.paper';

type GutterCellProps = {
  nestedLevel: number;
  lastOfNestedSet?: boolean;
  parentIsLastOfNestedSet?: boolean;
  side?: 'left' | 'right';
};
const GutterCell = memo(function _GutterCell({
  nestedLevel,
  children,
  side = 'left',
}: React.PropsWithChildren<GutterCellProps>) {
  // create a div for each level of indentation, using alternating colors
  const indentationCells = [...Array(nestedLevel)].map((_, i) => {
    return (
      <div
        className='ref-table-indentation-cell'
        key={i} // ok to use index since that's actually what's meaningful in this situation
      />
    );
  });

  const gutterCellContainerClasses =
    'ref-table-gutter-cell-container' +
    (side == 'right' ? ' ref-table-gutter-cell-container-right' : '');

  return (
    <TableCell className='ref-table-gutter-cell'>
      <div className={gutterCellContainerClasses}>
        {indentationCells}
        <div className='ref-table-gutter-cell-content-container'>
          {children}
        </div>
      </div>
    </TableCell>
  );
});

type RefTableRowsProps = {
  hasRefs: HasRefs;
  nestedLevel?: number;
  parentIsLastOfNestedSet?: boolean;
  show?: boolean;
};
const RefTableRows = memo(function _RefTableRows({
  hasRefs,
  nestedLevel,
  parentIsLastOfNestedSet = false,
  show = true,
}: RefTableRowsProps) {
  const sorter = useContext(SorterContext);
  const sortedRefs = useMemo(
    // if show is false, no need to sort. this still has to be done up here before the return
    // since all hooks must be called unconditionally
    () => (show ? sorter(hasRefs.refs) : []),
    [show, hasRefs.refs, sorter]
  );

  if (!show) {
    return null;
  }

  return (
    <>
      {sortedRefs.map((ref, i) => (
        <RefTableRow
          key={ref.resource.uniqueId}
          orchRef={ref}
          nestedLevel={nestedLevel}
          lastOfNestedSet={i === hasRefs.refs.length - 1}
          parentIsLastOfNestedSet={parentIsLastOfNestedSet}
        />
      ))}
    </>
  );
});

type RefTableRowProps = {
  orchRef: OrchestraComponentFieldGroupRef;
  nestedLevel?: number;
  lastOfNestedSet?: boolean;
  parentIsLastOfNestedSet?: boolean;
};
const RefTableRow = memo(function _RefTableRow({
  orchRef,
  nestedLevel = 0,
  lastOfNestedSet = false,
  parentIsLastOfNestedSet = false,
}: RefTableRowProps) {
  const componentOrGroup = isComponentRef(orchRef) || isGroupRef(orchRef);

  // add an id for scrolling by url hash. only support this for top-level resource, since those are the only ones
  // open on page load anyway. this also circumvents a potential problem with the same field appearing in multiple
  // places inside different components within a top-level component.
  const id = nestedLevel === 0 ? orchRef.resource.name : undefined;

  if (componentOrGroup) {
    return (
      <ComponentOrGroupRow
        id={id}
        componentOrGroupRef={orchRef}
        nestedLevel={nestedLevel}
        lastOfNestedSet={lastOfNestedSet}
        parentIsLastOfNestedSet={parentIsLastOfNestedSet}
      />
    );
  } else if (isFieldRef(orchRef)) {
    return (
      <FieldRow
        id={id}
        fieldRef={orchRef}
        nestedLevel={nestedLevel}
        lastOfNestedSet={lastOfNestedSet}
        parentIsLastOfNestedSet={parentIsLastOfNestedSet}
      />
    );
  }

  throw Error('unreachable');
});

// styles for the cell content. this is where the majority of the look-and-feel
// happens, since we've removed a lot of the styling from the outside td elements
function getRowClasses({
  nestedLevel,
  lastOfNestedSet,
  parentIsLastOfNestedSet,
}: {
  nestedLevel: number;
  lastOfNestedSet: boolean;
  parentIsLastOfNestedSet: boolean;
}): CommonProps['className'] {
  const evenOrOdd =
    nestedLevel % 2 == 0
      ? 'ref-table-nested-level-even'
      : 'ref-table-nested-level-odd';
  return (
    evenOrOdd +
    (lastOfNestedSet ? ' ref-table-last-of-nested-set' : '') +
    (parentIsLastOfNestedSet ? ' ref-table-parent-last-of-nested-set' : '')
  );
}

const ComponentOrGroupRow = memo(function _ComponentOrGroupRow({
  id,
  componentOrGroupRef,
  nestedLevel,
  lastOfNestedSet,
  parentIsLastOfNestedSet,
}: {
  id?: string;
  componentOrGroupRef: OrchestraComponentRef | OrchestraGroupRef;
  nestedLevel: number;
  lastOfNestedSet: boolean;
  parentIsLastOfNestedSet: boolean;
}) {
  const componentOrGroup: OrchestraComponent | OrchestraGroup =
    componentOrGroupRef.resource;

  const forcedExpansionState = useContext(ForcedExpansionStateContext);
  const [open, setOpen] = useState(false);

  // this is intentionally in a useEffect so that the component intially renders as closed
  // and then renders as open in a secondary pass. this gives much better visual performance
  // than waiting for the entire tree to render as open when 'expand all' is clicked
  useEffect(() => {
    if (forcedExpansionState.expanded !== null) {
      setOpen(forcedExpansionState.expanded);
    }
  }, [forcedExpansionState]);

  const effectiveNestedLevel = open ? nestedLevel + 1 : nestedLevel;
  const effectivelyLastInNestedSet = lastOfNestedSet && !open; // open means there are really more rows to come

  const rowClasses =
    getRowClasses({
      nestedLevel: effectiveNestedLevel,
      lastOfNestedSet: effectivelyLastInNestedSet,
      parentIsLastOfNestedSet,
    }) + (open ? ' ref-table-row-open' : '');

  return (
    <>
      <TableRow
        key={`componentOrGroup-${componentOrGroup.id}`}
        id={id}
        className={rowClasses}
      >
        <GutterCell
          nestedLevel={nestedLevel}
          lastOfNestedSet={lastOfNestedSet}
          parentIsLastOfNestedSet={parentIsLastOfNestedSet}
        >
          <div className='ref-table-cell-content ref-table-gutter-cell-content ref-table-component-button-cell'>
            <Button
              title={open ? 'Collapse' : 'Expand'}
              size='small'
              disableRipple={true}
              startIcon={open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              onClick={() => {
                // we don't want the last expand/collapse all click to influence open state anymore
                forcedExpansionState.reset();
                setOpen(!open);
              }}
            >
              Component
            </Button>
          </div>
        </GutterCell>
        <TableCell>
          <div className='ref-table-cell-content ref-table-link-cell'>
            <ResourceLink orchestraResource={componentOrGroup} />
          </div>
        </TableCell>
        <TableCell>
          <div className='ref-table-cell-content'></div>
        </TableCell>
        <TableCell>
          <div className='ref-table-cell-content'>
            {componentOrGroupRef.required ? 'Y' : null}
          </div>
        </TableCell>
        <TableCell>
          <div className='ref-table-cell-content'>
            <RefDocumentation
              documentation={componentOrGroupRef.documentation}
            />
          </div>
        </TableCell>
        <GutterCell
          nestedLevel={nestedLevel}
          lastOfNestedSet={lastOfNestedSet}
          parentIsLastOfNestedSet={parentIsLastOfNestedSet}
          side={'right'}
        >
          <div className='ref-table-cell-content'>
            {formatPedigree(componentOrGroupRef)}
          </div>
        </GutterCell>
      </TableRow>
      <RefTableRows
        show={open}
        hasRefs={componentOrGroup}
        nestedLevel={nestedLevel + 1}
        parentIsLastOfNestedSet={lastOfNestedSet}
      />
    </>
  );
});

const FieldRow = memo(function _FieldRow({
  id,
  fieldRef,
  nestedLevel,
  lastOfNestedSet,
  parentIsLastOfNestedSet,
}: {
  id?: string;
  fieldRef: OrchestraFieldRef;
  nestedLevel: number;
  lastOfNestedSet: boolean;
  parentIsLastOfNestedSet: boolean;
}) {
  const field = fieldRef.resource;

  const rowClasses = getRowClasses({
    nestedLevel,
    lastOfNestedSet,
    parentIsLastOfNestedSet,
  });

  return (
    <TableRow key={`field-${field.id}`} id={id} className={rowClasses}>
      <GutterCell
        nestedLevel={nestedLevel}
        lastOfNestedSet={lastOfNestedSet}
        parentIsLastOfNestedSet={parentIsLastOfNestedSet}
      >
        <div className='ref-table-cell-content ref-table-id-cell-content'>
          {field.id}
        </div>
      </GutterCell>
      <TableCell>
        <div className='ref-table-cell-content ref-table-link-cell'>
          <BottomDrawerLink resource={field} />
        </div>
      </TableCell>
      <TableCell>
        <div className='ref-table-cell-content ref-table-link-cell'>
          <DataTypeLink type={field.datatype} />
        </div>
      </TableCell>
      <TableCell>
        <div className='ref-table-cell-content'>
          {fieldRef.required ? 'Y' : null}
        </div>
      </TableCell>
      <TableCell>
        <div className='ref-table-cell-content'>
          <RefDocumentation documentation={fieldRef.documentation} />
        </div>
      </TableCell>
      <GutterCell
        nestedLevel={nestedLevel}
        lastOfNestedSet={lastOfNestedSet}
        parentIsLastOfNestedSet={parentIsLastOfNestedSet}
        side={'right'}
      >
        <div className='ref-table-cell-content'>{formatPedigree(fieldRef)}</div>
      </GutterCell>
    </TableRow>
  );
});

function RefDocumentation({ documentation }: { documentation?: string }) {
  if (!documentation) {
    return null;
  }

  // Use smaller text for consistency with other text in the table
  const overrides = {
    p: {
      component: Typography,
      props: { variant: 'body2', gutterBottom: true } as TypographyProps,
    },
  };

  return (
    <Markdown forceDisplay='block' overrides={overrides}>
      {documentation}
    </Markdown>
  );
}
