import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

export const CONFIG_PATH = '/orchimate-config/orchimate-config.json';

export type OrchestraSpecLocation = {
  name: string;
  slug: string;
  url: string;
};

export type FathomConfig = {
  siteId: string;
};

export type OrchestraHubConfig = {
  url: string;
};

export type AppConfig = {
  fathom?: FathomConfig;
  orchestraHub: OrchestraHubConfig;
  orchestraSpecs: OrchestraSpecLocation[];
};

export const ConfigContext = createContext<AppConfig | null>(null);

async function fetchConfig(configUrl: string): Promise<AppConfig> {
  const response = await fetch(configUrl);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return await response.json();
}

export function useOrchimateConfig(): AppConfig | null {
  return useContext(ConfigContext);
}

export function OrchimateConfigProvider({ children }: PropsWithChildren) {
  const [config, setConfig] = useState<AppConfig | null>(null);

  useEffect(() => {
    const doFetchConfig = async () => {
      const config = await fetchConfig(CONFIG_PATH);
      setConfig(config);
    };

    doFetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}
