import { useMatches } from 'react-router-dom';
import {
  ORCH_HUB_GROUP_SPEC_ROUTE_ID,
  ORCH_HUB_OFFICIAL_SPEC_ROUTE_ID,
  ORCHIMATE_SPEC_ROUTE_ID,
} from './routing';
import { useMemo } from 'react';

export type SpecIdentifier = {
  type: string;
};

export type OrchimateSpecIdentifier = SpecIdentifier & {
  type: 'orchimate';
  slug: string;
};

export type OrchHubSpecIdentifier = SpecIdentifier & {
  type: 'orch-hub';
  group: string;
  slug: string;
  version: string;
};

export function isOrchimateSpecIdentifier(
  specIdentifier: SpecIdentifier
): specIdentifier is OrchimateSpecIdentifier {
  return specIdentifier.type === 'orchimate';
}

export function isOrchHubSpecIdentifier(
  specIdentifier: SpecIdentifier
): specIdentifier is OrchHubSpecIdentifier {
  return specIdentifier.type === 'orch-hub';
}

const SPEC_IDENTIFIER_ROUTES = new Set([
  ORCHIMATE_SPEC_ROUTE_ID,
  ORCH_HUB_OFFICIAL_SPEC_ROUTE_ID,
  ORCH_HUB_GROUP_SPEC_ROUTE_ID,
]);

export function useSpecIdentifier():
  | OrchimateSpecIdentifier
  | OrchHubSpecIdentifier
  | null {
  // we're using useMatches and picking out the correct match rather than using useMatch, because useMatch
  // doesn't take into account route ambiguity and precedence, and could potentially match against a path
  // that the router would never actually match.
  // eg: calling useMatches() while on the path
  //     /fix-latest/components/StandardHeader
  // will always match
  //     /{namespace?=null}/{slug=fix-latest}/{version?=null}/components/{componentName=StandardHeader}
  // rather than
  //     /{namespace?=fix-latest}/{slug=components}/{version?=StandardHeader}
  //
  // but calling useMatch(':namespace?/:slug/:version?') will happily match the latter without context.
  const routeMatches = useMatches();

  const routeMatch = routeMatches.find((match) =>
    SPEC_IDENTIFIER_ROUTES.has(match.id)
  );

  const routeMatchId = routeMatch?.id;
  const { orchHubGroup, slug, version } = routeMatch?.params || {};

  return useMemo(() => {
    if (routeMatchId === ORCHIMATE_SPEC_ROUTE_ID) {
      if (!slug) {
        return null;
      }

      return {
        type: 'orchimate',
        slug: slug,
      } satisfies OrchimateSpecIdentifier;
    } else if (routeMatchId === ORCH_HUB_OFFICIAL_SPEC_ROUTE_ID) {
      if (!slug || !version) {
        return null;
      }

      return {
        type: 'orch-hub',
        group: 'official',
        slug: slug,
        version,
      } satisfies OrchHubSpecIdentifier;
    } else if (routeMatchId === ORCH_HUB_GROUP_SPEC_ROUTE_ID) {
      if (!orchHubGroup || !slug || !version) {
        return null;
      }

      return {
        type: 'orch-hub',
        group: orchHubGroup,
        slug: slug,
        version,
      } satisfies OrchHubSpecIdentifier;
    } else {
      return null;
    }
  }, [routeMatchId, orchHubGroup, slug, version]);
}
