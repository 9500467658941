import { load } from 'fathom-client';
import { useEffect } from 'react';
import { FathomConfig } from './config';

export function useFathom(config?: FathomConfig) {
  useEffect(() => {
    if (!config) {
      return;
    }

    load(config.siteId, { spa: 'auto' });
  }, [config]);
}
