import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scrolls the element pointed at by the provided ref to the top of the page any time the location changes. Otherwise,
// if we have scrolled to the bottom of a page, we'll start at the bottom of the next page we navigate to.
//
// Ideally, we'd instead remember scroll locations as we move around, and restore the last scroll location known
// for the new page, but this turns out to be fairly difficult in our case. React-router provides a component
// called <ScrollRestoration> that is intended to do this, but it doesn't work if the scrollable content is not
// the window itself (see https://github.com/remix-run/react-router/discussions/9495).
// I tried for a while without luck to manually save scroll positions, but I could not find a consistent event that
// would fire before navigation. The best alternative I could find was to trigger the save on our <InternalLink>
// clicks, but this doesn't handle navigation from the left panel. We could try to unify the way this is done, but
// the implementation would be rather fragile. This seems like something worth more exploration.
export function useScrollToTopOnLocationChange(
  ref: React.RefObject<HTMLElement>
) {
  const { pathname, hash } = useLocation();

  useLayoutEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    // if there's a hash, go there instead
    if (hash) {
      const hashWithoutHash = hash.substring(1);
      const hashEl = document.getElementById(hashWithoutHash);
      if (hashEl) {
        document.getElementById(hashWithoutHash)?.scrollIntoView();
        // a bit hacky - account for the fact that the AppBar overlaps some content
        ref.current?.scrollBy(0, -64);
      }
      return;
    }

    ref.current?.scrollTo(0, 0);
  }, [ref, pathname, hash]);
}
