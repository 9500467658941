import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { OrchestraReferrable, OrchestraResource } from './OrchestraSpec';
import { useSearchParams } from 'react-router-dom';
import { detailPaneQueryParam } from './BottomDrawer';
import { OrchestraSpecWithInfoContext } from './OrchestraSpecManager';

export type PathInfo = {
  specBasePath: string | null;
};
export const PathContext = createContext<PathInfo>({ specBasePath: null });

export type ResourceSlugSpecifier = Pick<OrchestraResource, 'name' | 'type'>;

export function PathContextProvider({ children }: PropsWithChildren) {
  const orchestraSpecContext = useContext(OrchestraSpecWithInfoContext);

  const pathContext = useMemo(
    () => ({
      specBasePath: orchestraSpecContext
        ? `/${orchestraSpecContext.info.slug}`
        : '',
    }),
    [orchestraSpecContext]
  );

  return (
    <PathContext.Provider value={pathContext}>{children}</PathContext.Provider>
  );
}

export const usePathHelper = () => {
  const pathInfo = useContext(PathContext);
  const [currentSearchParams] = useSearchParams();

  return useMemo(() => {
    const base = pathInfo.specBasePath || '';
    return {
      toResource: (
        resource: ResourceSlugSpecifier,
        {
          discardDetailPane = false,
          scrollToRef,
        }: {
          discardDetailPane?: boolean;
          scrollToRef?: OrchestraReferrable;
        } = {}
      ) => {
        const newSearchParams = new URLSearchParams();
        if (!discardDetailPane) {
          const key = detailPaneQueryParam;
          const currentValue = currentSearchParams.get(key);
          if (currentValue !== null) {
            newSearchParams.set(key, currentValue);
          }
        }
        // RefTable uses the resource's name as the tr's id
        const hash = scrollToRef ? `#${scrollToRef.name}` : '';
        return `${base}/${slugFromResource(
          resource
        )}?${newSearchParams}${hash}`;
      },
    };
  }, [pathInfo, currentSearchParams]);
};

const typeToResourceSlugPrefix: Map<ResourceSlugSpecifier['type'], string> =
  new Map([
    ['codeSet', 'codeSets'],
    ['component', 'components'],
    ['datatype', 'datatypes'],
    ['field', 'fields'],
    ['group', 'groups'],
    ['message', 'messages'],
  ]);
const resourceSlugPrefixToType = new Map(
  Array.from(typeToResourceSlugPrefix).map(([type, prefix]) => [prefix, type])
);

export const slugFromResource = ({
  name,
  type,
}: ResourceSlugSpecifier): string => {
  const prefix = typeToResourceSlugPrefix.get(type);
  if (!prefix) {
    throw Error(`Couldn't get slug for resource type: ${type}`);
  }
  return `${prefix}/${name}`;
};

export const resourceFromSlug = (slug: string): ResourceSlugSpecifier => {
  const [slugPrefix, name] = slug.split('/');
  const type = resourceSlugPrefixToType.get(slugPrefix);
  if (!type) {
    throw Error('Unknown resource slug prefix: ' + slugPrefix);
  }
  return { type, name };
};
