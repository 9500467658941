import { createTheme, PaletteOptions } from '@mui/material/styles';

// Shared Orchestra site theming: https://atomicwire.atlassian.net/wiki/spaces/AWP/pages/762740737/Orchestra+Site+Theming
export const theme = createTheme({
  palette: {
    primary: {
      main: '#2b638b',
    },
    secondary: {
      main: '#66587b',
    },
    background: {
      paper: '#ffffff',
      default: '#fcfcff',
    },
  } satisfies PaletteOptions,
  components: {
    MuiLink: {
      styleOverrides: {
        // makes sure that button links get font styles from their parents rather than straight from the user agent
        // see https://github.com/mui/material-ui/issues/32942 and https://github.com/mui/material-ui/pull/33621
        button: {
          fontFamily: 'inherit',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
        },
      },
    },
  },
});
